import React, { useState, Fragment, useEffect } from 'react'
import { Container, Row, Col, Card, CardBody, CardHeader, Button, CardFooter, Table, Input, InputGroup,
  InputGroupAddon,
  InputGroupText, Collapse,Label,Modal, ModalHeader, ModalBody,ModalFooter, FormGroup,Media } from 'reactstrap'
import axios from 'axios';
import { DollarSign, TrendingUp, Calendar, Volume2, Phone, Pause, Coffee, Clock, Droplet, Users } from 'react-feather'
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock'
import ScrollArea from "react-scrollbar";
import USUARIOS from "../../assets/images/blog/USUARIOS.png";
import SweetAlert from 'sweetalert2'
import editus from '../../assets/images/editar_usuario.png'
import { toast } from 'react-toastify';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';

const UsuariosList = () => {
  

    //Variables
    const [usuarioslist, setusuarioslist] = useState([])
    const [verlsch, setverlsch] = useState(false)
    const [numprov, setnumprov] = useState([""])
    const [numcomp, setnumcomp] = useState([""])



    //LLAMADA A LA API

    useEffect(() => {
        axios.get('https://b2bhubapi.com:8000/usuarioslist').then((response) => {
              setusuarioslist(response.data);
            });
          }, []);
          useEffect(() => {
            axios.get('https://b2bhubapi.com:8000/listprov').then((response) => {
                  setnumprov(response.data.length);        
                });
              }, []);
        
              useEffect(() => {
                axios.get('https://b2bhubapi.com:8000/listcomp', ).then((response) => {
                      setnumcomp(response.data.length);        
                    });
                  }, []);

                  const topCardState = [
                    { id: 3, cardBg: 'bg-primary', title: "Compradores", scorr: numcomp, color: 'light', bdgeFont: 'font-primary', bdgeValue: '1.36%', progress: '5%' },
                  ]
                  const Topcard =[
                    { id: 4, cardBg: '', title: "Proveedores", scorr: numprov, color: 'primary', bdgeFont: '', bdgeValue: '6.23%', progress: '35%' }
                  ]




        

  const disableBody = target => disableBodyScroll(target);
  const enableBody = target => enableBodyScroll(target);

  //lod emas
  const [date, setDate] = useState({ date: new Date() });
  const handleChange = date => {
    setDate(date)
  };


  const [isFilter, setIsFilter] = useState(true);
  const [isFilter2, setIsFilter2] = useState(false);
  const [keyWord, setKeyWord] = useState("");
  const [keyWord2, setKeyWord2] = useState("");
  const [serachedItems, setSearchedItems] = useState([]);


  const searchKeyWord = () => {
    if (keyWord != "") {
      let itemsMatched = usuarioslist.filter((item) =>
        item.Nombre.toLowerCase().includes(keyWord)
      );

      if(itemsMatched == ""){
        SweetAlert.fire({title:"Upps... No hay resultados para tu busqueda", text:"Intenta nuevamente con otro criterio", icon:"error"});
      setSearchedItems([]);
      }else{
        setSearchedItems(itemsMatched);
      }

    } else {
        SweetAlert.fire({title:"No has buscado nada", text:"Escribe en el campo para buscar un nombre de usuario", icon:"error"});
      setSearchedItems([]);
    }
  };
    
  const searchKeyWord2 = () => {
    if (keyWord2 != "") {
         let itemsMatched = usuarioslist.filter(obj => obj.role == keyWord2)
         if(itemsMatched == ""){
          SweetAlert.fire({title:"Upps... No hay resultados para tu busqueda", text:"Intenta nuevamente con otro criterio", icon:"error"});
        setSearchedItems([]);
        }else{
          setSearchedItems(itemsMatched);
        }
  
      } else {
          SweetAlert.fire({title:"No has buscado nada", text:"Escribe en el campo para buscar un nombre de usuario", icon:"error"});
        setSearchedItems([]);
      }
  };




            //  variables modal
             const [modal, setmodal] = useState(false);


             //toggle 

             const toggle = () =>{
              setmodal(false)
             }

             //datos seleccionados
             const [seleccionado, setseleccionado] = useState({
              Nombre: '',
              Apellido:'',
              Empresa:'',
              Categoria:'',
              role:'',
              Telefono:'',
              Correo:'',
              Fecha:'',
              Hora:''
            });

             //variables de estado de los datos

             const [id, setid] = useState ({
              id:'' 
             });

             const [estadonombre, setestadonombre] = useState ({
              Nombre:'' 
             });
             const [estadoape, setestadoape] = useState ({
              Apellido:'' 
             });
             const [estadorole, setestadorole] = useState ({
              role:'' 
             });
             const [estadoemp, setestadoemp] = useState ({
              Empresa:'' 
             });
             const [estadotel, setestadotel] = useState ({
              Telefono:'' 
             });
             const [estadocorreo, setestadocorreo] = useState ({
              Correo:'' 
             });
             const [estadocat, setestadocat] = useState ({
              Categoria:'' 
             });
             const [estadofch, setestadofch] = useState ({
              Fecha:'' 
             });
             const [estadohr, setestadohr] = useState ({
              Hora:'' 
             });
             //VARIABLES INTERCAMBIABLES
             const onChangenom = event =>{
              setestadonombre({ Nombre: event.target.value}, () =>
              console.log(this.state.Nombre)
              );
            } 
            const onChangeape = event =>{
              setestadoape({ Apellido: event.target.value}, () =>
              console.log(this.state.Apellido)
              );
            } 
            const onChangerole = event =>{
              setestadorole({ role: event.target.value}, () =>
              console.log(this.state.role)
              );
            } 
            const onChangeemp = event =>{
              setestadoemp({ Empresa: event.target.value}, () =>
              console.log(this.state.Empresa)
              );
            } 
            const onChangetel = event =>{
              setestadotel({ Telefono: event.target.value}, () =>
              console.log(this.state.Telefono)
              );
            } 
            const onChangecat = event =>{
              setestadocat({ Categoria: event.target.value}, () =>
              console.log(this.state.Categoria)
              );
            } 
            


       const Editarusuario = (data) =>{
        if(data.role == "Admin"){
          SweetAlert.fire({title:"No se puede editar un usuario administrador", text:"Contacta a soporte para mas información", icon:"error"});
          
        }else{
          setmodal(true)
          setid(data)
          setseleccionado(data)
          setestadonombre(data)
          setestadoape(data)
          setestadorole(data)
          setestadoemp(data)
          setestadotel(data)
          setestadocorreo(data)
          setestadocat(data)
          setestadofch(data)
          setestadohr(data)
        }
              
       }


       const actualizar = () =>{
        const LoadingMsg = () => (
          <div>
           <i className="fa fa-bell-o" ></i><strong className="ml-2">{"Cargando..."}</strong>    {""}
          </div>
        )
        if(estadonombre.Nombre == seleccionado.Nombre && estadoape.Apellido == seleccionado.Apellido && estadoemp.Empresa == seleccionado.Empresa && estadocat.Categoria == seleccionado.Categoria && estadotel.Telefono == seleccionado.Telefono && estadorole.role == seleccionado.role){
          SweetAlert.fire({title:"ERROR", text:"No has escrito ningún cambio.", icon:"error"});  
        }else{      
          setmodal(false)
          toast(<LoadingMsg />, {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 3000,
            });
          axios.put("https://b2bhubapi.com:8000/actualizarusuarios", {
            Nombre:estadonombre.Nombre,
            Apellido: estadoape.Apellido,
            Empresa:estadoemp.Empresa,
            Telefono:estadotel.Telefono,
            Categoria: estadocat.Categoria,
            role: estadorole.role,          
            id: id.id
           }).then(res => {                        
             SweetAlert.fire({
              icon:"success",
              title:"Datos actualizados correctamente.",    
            }).then(async(result) => {
                    window.location.href = (`${process.env.PUBLIC_URL}/#/administrador/Usuarios`)
                    window.location.reload();
              })
           })                    
          
        }
        
      }



  return (
    <Fragment>
      <Card>
        <CardHeader>
         <img className="pull-left" src={USUARIOS} style={{height: "35px"}} alt=""/>
        </CardHeader>
        <Row>
              {topCardState.map(data => (
                <Col xl="3" md="6" className="xl-50 box-col-6" key={data.id}>
                  <Card className={data.cardBg}>
                    <CardBody className="tag-card">
                      <div className="progressbar-widgets">
                        <div className="media media-widgets">
                          <div className="media-body">
                            <p className={`mb-0 ${data.color === 'light' ? 'font-light' : ''}`}>{data.title}</p>
                            <h3 className="mt-0 mb-0 f-w-600"><span className="counter">{data.scorr}</span><span><TrendingUp /></span></h3>
                          </div>
                        </div>
                        <div className="progress sm-progress-bar progress-animate">
                          <div className={`progress-gradient-${data.color}`} role="progressbar" style={{ 'width': data.progress }} aria-valuenow="75"
                            aria-valuemin="0" aria-valuemax="100">
                            <span className={`font-${data.color}`}>{data.progress}</span><span className="animate-circle"></span></div>
                        </div><span className={`tag-content-${data.color} tag-hover-effect ${data.color === 'light' ? 'tag-light' : ''}`}><TrendingUp /></span>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              ))}

{Topcard.map(data => (
                <Col xl="3" md="6" className="xl-50 box-col-6" key={data.id}>
                  <Card className={data.cardBg}>
                    <CardBody className="tag-card">
                      <div className="progressbar-widgets">
                        <div className="media media-widgets">
                          <div className="media-body">
                            <p className={`mb-0 ${data.color === 'light' ? 'font-light' : ''}`}>{data.title}</p>
                            <h3 className="mt-0 mb-0 f-w-600"><span className="counter">{data.scorr}</span><span><TrendingUp /></span></h3>
                          </div>
                        </div>
                        <div className="progress sm-progress-bar progress-animate">
                          <div className={`progress-gradient-${data.color}`} role="progressbar" style={{ 'width': data.progress }} aria-valuenow="75"
                            aria-valuemin="0" aria-valuemax="100">
                            <span className={`font-${data.color}`}>{data.progress}</span><span className="animate-circle"></span></div>
                        </div><span className={`tag-content-${data.color} tag-hover-effect ${data.color === 'light' ? 'tag-light' : ''}`}><TrendingUp /></span>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              ))}
              </Row>
           <CardBody>
            <InputGroup className="p3">
                              <Input
                                type="text"
                                class="form-control m-t-5 m-b-5"
                                placeholder="Nombre"
                                value={keyWord}
                                disabled={serachedItems.length == 0 ? false : true}
                                onChange={(event) =>
                                  setKeyWord(event.target.value.toLowerCase())
                                } />
                              <InputGroupAddon addonType="append">
                                <InputGroupText>
                                  {serachedItems.length == 0 ? (
                                    <Button  className="btn-pill pull-right" color="warning-gradien" onClick={searchKeyWord}>
                                      {"Buscar"}
                                    </Button>
                                  ) : (
                                    <Button
                                    className="btn-pill pull-right" outline color="danger"
                                      onClick={() => {
                                        setKeyWord("");
                                        setSearchedItems([]);
                                      }}
                                    >
                                      {"Limpiar"}
                                    </Button>
                                  )}
                                </InputGroupText>
                              </InputGroupAddon>
                            </InputGroup>
                            
                          <FormControl variant="standard" sx={{ m: 1, minWidth: 850 }}>
                          <InputLabel color="error" id="demo-simple-select-standard-label" 
                          required>Selecciona un rol de usuario</InputLabel>
                          <Select
                           labelId="demo-simple-select-standard-label"
                          id="demo-simple-select-standard"
                          onChange={(event) =>
                            setKeyWord2(event.target.value)
                          }
                          disabled={serachedItems.length == 0 ? false : true}
                           >
                          <MenuItem value="">
                           <em>Ninguno</em>
                          </MenuItem>
                          <MenuItem value={"Comprador"}>Comprador</MenuItem>
                          <MenuItem value={"Proveedor"}>Proveedor</MenuItem>
                          </Select>
                          </FormControl>
                            <InputGroup className="p3">
                              <InputGroupAddon addonType="append">
                                <InputGroupText>
                                  {serachedItems.length == 0 ? (
                                    <Button  className="btn-pill pull-right" color="warning-gradien" onClick={searchKeyWord2}>
                                      {"Buscar"}
                                    </Button>
                                  ) : (
                                    <Button
                                    className="btn-pill pull-right" outline color="danger"
                                      onClick={() => {
                                        setKeyWord2("");
                                        setSearchedItems([]);
                                      }}
                                    >
                                      {"Limpiar"}
                                    </Button>
                                  )}
                                </InputGroupText>
                              </InputGroupAddon>
                            </InputGroup>
           </CardBody>
           
              </Card> 
              
              


            <Card id="verls">
              <CardBody>
              {serachedItems.length == 0 ? (
                 <Table>
         <thead>
           <tr>
             <th scope="col">{"Nombre"}</th>
             <th scope="col">{"Empresa"}</th>
             <th scope="col">{"rol"}</th>
             <th scope="col">{"Fecha de creación"}</th>          
             <th scope="col">{"Hora"}</th>
             <th scope="col">{"Acciones"}</th>
           </tr>
         </thead>
              <tbody>                       
              {usuarioslist.map((data) => {   
           
                return(
                <tr >
                  <td>{data.Nombre}</td>
                  <td>{data.Empresa}</td>
                  <td>{data.role}</td>
                  <td>{data.Fecha}</td>
                  <td>{data.Hora}</td>
                  <td><span className="badge badge-pill pill-badge-warning" style={{cursor:'pointer'}} onClick={() => Editarusuario(data)}>{"Editar"}</span></td>
                  </tr>
                )
              }
              )}
            </tbody>
            </Table>   
        
              ) : (
                           <Table>
         <thead>
           <tr>
             <th scope="col">{"Nombre"}</th>
             <th scope="col">{"Empresa"}</th>
             <th scope="col">{"rol"}</th>
             <th scope="col">{"Fecha de creación"}</th>          
             <th scope="col">{"Hora"}</th>
             <th scope="col">{"Acciones"}</th>
           </tr>
         </thead>
              <tbody>                       
              {serachedItems.map((data) => {   
           
                return(
                <tr >
                  <td>{data.Nombre}</td>
                  <td>{data.Empresa}</td>
                  <td>{data.role}</td>
                  <td>{data.Fecha}</td>
                  <td>{data.Hora}</td>
                  <td><span className="badge badge-pill pill-badge-warning" style={{cursor:'pointer'}} onClick={() => Editarusuario(data)}>{"Editar"}</span></td>
                  </tr>
                )
              }
              )}
            </tbody>
            </Table>     
              )}            
              </CardBody>
            </Card>

                     <Modal className="modal-lg modal-dialog-centered product-modal" isOpen={modal}>
            <ModalHeader className="d-inline-block f-w-600">{"Edición de usuario"}
                        </ModalHeader>
                    <ModalBody>
                        <ModalHeader>
                          <div className="product-box row">
                              <Col lg="6" className="product-img">
                                <Media className="img-fluid" src={editus} alt=""/>
                              </Col>
                              <Col lg="6" className="product-details  text-left">
                                <div className="product-view">
                                <ScrollArea horizontal={true} vertical={true}>
                                <FormGroup>              
                                  <p className="mb-0">{"Rol:"}</p>
                                  <Input type="select" className="custom-select" onChange={onChangerole} value={estadorole && estadorole.role} autoComplete="on">
                                  <option >{""}</option>
                                  <option >{"Proveedor"}</option>
                                  <option >{"Comprador"}</option>
                                 </Input>
                                 </FormGroup>   
                                <FormGroup>              
                                  <p className="mb-0">{"Nombre:"}</p>
                                <Input className="form-control"  type="text" name="inputUsername" onChange={onChangenom} value={estadonombre && estadonombre.Nombre} autoComplete="on" />
                                 </FormGroup> 
                                 <FormGroup>              
                                  <p className="mb-0">{"Apellido:"}</p>
                                <Input className="form-control"  type="text" name="inputUsername" onChange={onChangeape} value={estadoape && estadoape.Apellido} autoComplete="on" />
                                 </FormGroup>        
                                 <FormGroup>              
                                  <p className="mb-0">{"Empresa:"}</p>
                                <Input className="form-control"  type="text" name="inputUsername" onChange={onChangeemp} value={estadoemp && estadoemp.Empresa} autoComplete="on" />
                                 </FormGroup>                      
                                 <FormGroup>              
                                  <p className="mb-0">{"Numero de telefono:"}</p>
                                <Input className="form-control"  type="text" name="inputUsername" onChange={onChangetel} value={estadotel && estadotel.Telefono} autoComplete="on" />
                                 </FormGroup>                
                                 <FormGroup>              
                                  <p className="mb-0">{"Correo Electronico:"}</p>
                                <Input className="form-control"  type="text" name="inputUsername" value={seleccionado.Correo} autoComplete="on" />
                                 </FormGroup>   
                                 <FormGroup>              
                                  <p className="mb-0">{"Categoria:"}</p>
                                <Input className="form-control"  type="textarea"  name="inputUsername" onChange={onChangecat} value={estadocat && estadocat.Categoria} autoComplete="on" />
                                 </FormGroup>   
                                 <FormGroup>              
                                  <p className="mb-0">{"Fecha de creación:"}</p>
                                <Input className="form-control"  type="text" name="inputUsername" value={seleccionado.Fecha} autoComplete="on" />
                                 </FormGroup>   
                                 <FormGroup>              
                                  <p className="mb-0">{"Hora de creación:"}</p>
                                <Input className="form-control"  type="text" name="inputUsername" value={seleccionado.Hora} autoComplete="on" />
                                 </FormGroup>   
                                 </ScrollArea>
                                </div>
                              </Col>
                          </div>
                        </ModalHeader>
                      </ModalBody>       
                      <ModalFooter>
                        <Button color="primary" onClick={toggle}>{"Cancelar y salir"}</Button>
                        <Button color="secondary" onClick={actualizar}>{"Actualizar"}</Button>
                        </ModalFooter>                   
                  </Modal>     
    </Fragment>
  )
}

export default UsuariosList