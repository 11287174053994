
// Users
import UserProfile from '../components/users/UserProfile'
import UserEdit from '../components/users/UserEdit'
import UserCards from '../components/users/UserCards'

//ADMINISTRADOR
import Panel from '../components/administrador/Panel'
import Cotizaciones from '../components/administrador/Cotizaciones'
import Usuarios from '../components/administrador/UsuariosList'
import Reporte from '../components/administrador/Reporte'
// Dashboard
import Default from '../components/dashboard/Default'
import Hospital from '../components/dashboard/Hospital'
import Sass from '../components/dashboard/Sass'
import Crm from '../components/dashboard/Crm'
import Crypto from '../components/dashboard/Crypto'

// Base
import Listaprov from '../components/widgets/RFQSprov'
import RFQSList from '../components/widgets/RFQSList'
import DashboardProveedor from '../components/widgets/DashboardProveedor'
import DashboardComprador from '../components/widgets/DashboardComprador'
import CrearRFQ from '../components/widgets/CrearRFQ'
import Bienvenida from '../components/widgets/Bienvenida'
import Pago from '../components/widgets/Pago'
import Check from '../components/widgets/Check'
import Pago2 from '../components/widgets/Pago2'
import Check2 from '../components/widgets/Check2'
import Resetpwd from "../pages/authentication/Resetpwd";




export const routes = [

  { path: '/administrador/Panel', Component: Panel },
  { path: '/administrador/Usuarios', Component: Usuarios },
  { path: '/administrador/Cotizaciones', Component: Cotizaciones },
  { path: '/administrador/Reporte', Component: Reporte },

  { path: '/dashboard/default', Component: Default },
  { path: "/dashboard/hospital",Component: Hospital },
  { path: "/dashboard/sass",Component: Sass},
  { path: "/dashboard/crm",Component: Crm},
  { path: "/dashboard/crypto",Component: Crypto},
  { path: "/widgets/RFQSprov",Component: Listaprov},
  { path: "/widgets/RFQSList",Component: RFQSList},
  { path: "/widgets/dashboardproveedor",Component: DashboardProveedor},
  { path: "/widgets/dashboardcomprador",Component: DashboardComprador},
  { path: "/widgets/CrearRFQ",Component: CrearRFQ},
  { path: "/widgets/Bienvenida",Component: Bienvenida},
  { path: "/widgets/Pago",Component: Pago},
  { path: "/widgets/Check",Component: Check},
  { path: "/widgets/Pago2",Component: Pago2},
  { path: "/widgets/Check2",Component: Check2},
  { path: "/users/userProfile" ,Component: UserProfile},
  { path: "/users/userEdit" ,Component: UserEdit},
  { path: "/users/userCards" ,Component: UserCards},
]
