import React, {useEffect, useState} from 'react';
import {Container,Row,Col,Card,Form,FormGroup,Label,Input,Button,Modal, ModalBody} from 'reactstrap' 
import emailjs from 'emailjs-com'
import axios from 'axios';
import SweetAlert from 'sweetalert2'
const Olvidastetucontraseña = (props) => {


  const [correo, setcorreo] = useState('')
  const [modal, setmodal] = useState(false)


  const toggle = () =>{
    setmodal(false)
  }



  const enviaremail = () => {
    setmodal(true)
    axios.post('https://5ef65c592a37.ngrok.io/restpwd1203',
    {correo: correo}, 
  ).then((response) => { 
    
     if (response.data.message){
      setmodal(false)
      setTimeout(() => {
        SweetAlert.fire({
          icon:"error",
          text:"El correo es inválido o no existe",
          title:"Uppps...",    
          confirmButtonText: "Registrate ahora",
          cancelButtonText:"Prueba con otro correo",
          cancelButtonColor: "#6596CC",
          reverseButtons: true,
          showCancelButton: true,
        }).then(async(result) => {
          if(result.isConfirmed == true){
            window.location.href = (`${process.env.PUBLIC_URL}/#/`)
          }
        });
    }, 500);
     }else{
           
    var params = {
      toName:response.data[0].Nombre, 
      toEmail:correo,
      message: response.data[0].Contraseña

    }
      emailjs.send('service_b3di7yj', 'template_7eb0nfq',params,'HXng18b-IS5R6x1e-').then((res)=>{
         console.log('emailjs', res)
         setmodal(false)
         SweetAlert.fire({title:"Se ha enviado un correo de recuperación", text:"Revisa tu bandeja de entrada y sigue disfrutando de B2BuySel.",
          icon:"success",
          confirmButtonText: "Listo",
        }).then(async(result) => {
          if(result.isConfirmed == true){
            window.location.href = (`${process.env.PUBLIC_URL}/#/`)
          }
        });
        setTimeout(() => {
          window.location.href = (`${process.env.PUBLIC_URL}/#/`)
        }, 3000);
  
      })


     }
    }
  )

  }
  




    return (
    <div className="page-wrapper">
      <Container fluid={true}>
        <div className="authentication-main mt-0">
          <Row>
            <Col md="12" className="p-0">
              <div className="auth-innerright auth-minibox">
                <div className="reset-password-box">
                  <div className="text-center"><img src={require("../../src/assets/images/other-images/creative-logo1.png")} alt=""/></div>
                  <Card className="mt-4 mb-0">
                    <h4>{"Recupera tu contraseña"}</h4>
                    <Form className="theme-form">
                      <FormGroup>
                        <Label className="col-form-label">{"Introduce tu correo electrónico"}</Label>
                        <Row form>
                          
                          <Col md="9" >
                            <Input onChange= {(e) => {setcorreo(e.target.value)}} className="form-control digits mb-1" type="email" placeholder="ejemplo: b2buysel@b2b.com"/>
                          </Col>
                          <Col md="2">
                            <Button color="primary m-0" onClick={enviaremail}>{"Solicitar"}</Button>
                          </Col>
                        </Row>        
                      </FormGroup>
                    </Form>
                  </Card>
                  <Modal isOpen={modal} toggle={toggle} size="lg" style={{width:'530px'}} centered>
                        <ModalBody>
                        <Col md="12">
                      <div className="loader-box">
                        <div  className="loader-19"></div>
                      </div>
                    </Col>
                        </ModalBody>
                        </Modal>

                </div>
              </div>
            </Col>
          </Row>
        </div>
      </Container>
    </div>
    );
}

export default Olvidastetucontraseña;