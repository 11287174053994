import React, {useState, useEffect,Fragment } from 'react'
import ChartistGraph from 'react-chartist';
import { operationSmallChart,visitorSmallChart,medicineSmallChart, hospitalSmallChartOptions, smallChartListener, hospitalCurveChart, hospitalCurveChartOptions } from '../dashboard/chartsData/chartist'
import {Media,Label, Row, Col, Card, CardBody, CardHeader, ButtonGroup, Button, Table, CardFooter,Modal, ModalHeader, ModalBody,ModalFooter, Input, FormGroup} from 'reactstrap'
import { toast } from 'react-toastify';
import COTIZACIONES from "../../assets/images/blog/COTIZACIONES.png";
import axios from 'axios';
import box from '../../assets/images/carton_box.png'
import ScrollArea from "react-scrollbar";
import SweetAlert from 'sweetalert2'
import { set } from 'lodash';

const Cotizaciones= () => {

    //VARIABLES DE TABLA

    const [numcotbuysel, setnumcotbuysel] = useState([""])
    const [cotbuysel, setcotbuysel] = useState([])
    const [numcotacep, setnumcotacep] = useState([""])
    const [cotacep, setcotacep] = useState([])
    const [numcotganadores, setnumcotganadores] = useState([""])
    const [cotganadores, setcotganadores] = useState([])
    //LLAMADAS A API

    useEffect(() => {
        axios.get('https://b2bhubapi.com:8000/cotbuysel').then((response) => {
              setnumcotbuysel(response.data.length);
              setcotbuysel(response.data);
            });
          }, []);
          useEffect(() => {
            axios.get('https://b2bhubapi.com:8000/cotaceptados').then((response) => {
                  setnumcotacep(response.data.length);
                  setcotacep(response.data);
                });
              }, []);
              useEffect(() => {
                axios.get('https://b2bhubapi.com:8000/cotganadores').then((response) => {
                      setnumcotganadores(response.data.length);
                      setcotganadores(response.data);
                    });
                  }, []);


                  //MODAL VARIABLES

                  const [modal, setmodal] = useState(false);
                  const [modal2, setmodal2] = useState(false); 
                  const [modal3, setmodal3] = useState(false);



                  //toggle o cerrar modal

                  const CerrarModal = () => {
                    setmodal(false)
                  }

                  const CerrarModal2 = () =>{
                    setmodal2(false)
                  }

                  const CerrarModal3 = () =>{
                    setmodal3(false)
                  }
  

                  //Abrir modal

                  const abrirmodal = (data) =>{
                    setseleccionado(data)
                    setestadorfq(data)
                    setestadoemp(data)
                    setestadocat(data)
                    setestadodesc(data)
                    setestadocs(data)
                    setestadoum(data)
                    setestadomon(data)
                    setestadofch(data)
                    setestadolg(data)
                    setestadoest(data)
                    setid(data)
                    setmodal(true)
                  }

                  const abrirmodal2 = (data) =>{
                    setseleccionado(data)
                    setestadorfq(data)
                    setestadoemp(data)
                    setestadocat(data)
                    setestadodesc(data)
                    setestadocs(data)
                    setestadoum(data)
                    setestadomon(data)
                    setestadofch(data)
                    setestadolg(data)
                    setestadoest(data)
                    setid(data)
                    setmodal2(true)
                  }

                  const abrirmodal3 = (data) =>{
                    setseleccionado(data)
                    setestadorfq(data)
                    setestadoemp(data)
                    setestadocat(data)
                    setestadodesc(data)
                    setestadocs(data)
                    setestadoum(data)
                    setestadomon(data)
                    setestadofch(data)
                    setestadolg(data)
                    setestadoest(data)
                    setid(data)
                    setmodal3(true)
                  }


                  // VARIABLES ALMACENANTES
                  const [id, setid] = useState ({
                   id:'' 
                  });

                  const [estadorfq, setestadorfq] = useState ({
                    RFQsl:'' 
                  });
                  const [estadoemp, setestadoemp] = useState ({
                    Empresa_solicitante:'' 
                  });
                  const [estadocat, setestadocat] = useState ({
                    Categoria:'' 
                  });
                  const [estadodesc, setestadodesc] = useState ({
                    Descripcion:'' 
                  });
                  const [estadocs, setestadocs] = useState ({
                    Cantidad_Solicitada:'' 
                  });
                  const [estadoum, setestadoum] = useState ({
                    Unidadmedida:'' 
                  });
                  const [estadomon, setestadomon] = useState ({
                    Moneda:'' 
                  });
                  const [estadofch, setestadofch] = useState ({
                    Fechavigencia:'' 
                  });
                  const [estadolg, setestadolg] = useState ({
                    lugaentrega:'' 
                  });
                  const [estadoest, setestadoest] = useState ({
                    Estatussl:'' 
                  });
  
                  //datos seleccionados
                  const [seleccionado, setseleccionado] = useState({
                    id: '',
                    RFQsl:'',
                    Empresa_solicitante:'',
                    Categoria:'',
                    Descripcion:'',
                    Cantidad_Solicitada:'',
                    Unidadmedida:'',
                    Precioobj:'',
                    diasdecredito:'', 
                    Moneda:'', 
                    Fechavigencia:'',
                    lugaentrega:'',
                    Estatussl:''
                  });

                  //VARIABLES INTERCAMBIABLES
                  const onChangerfq = event =>{
                    setestadorfq({ RFQsl: event.target.value}, () =>
                    console.log(this.state.RFQsl)
                    );
                  } 
                  const onChangeemp = event =>{
                    setestadoemp({ Empresa_solicitante: event.target.value }, () =>
                    console.log(this.state.Empresa_solicitante)
                    );
                  } 
                  const onChangecat = event =>{
                    setestadocat({ Categoria: event.target.value }, () =>
                    console.log(this.state.Categoria)
                    );
                  } 
                  const onChangedesc = event =>{
                    setestadodesc({ Descripcion: event.target.value }, () =>
                    console.log(this.state.Descripcion)
                    );
                  } 
                  const onChangecans = event =>{
                    setestadocs({ Cantidad_Solicitada: event.target.value }, () =>
                    console.log(this.state.Cantidad_Solicitada)
                    );
                  } 
                  const onChangeum = event =>{
                    setestadoum({ Unidadmedida: event.target.value }, () =>
                    console.log(this.state.Unidadmedida)
                    );
                  } 
                  const onChangemon = event =>{
                    setestadomon({  Moneda: event.target.value }, () =>
                    console.log(this.state.Moneda)
                    );
                  } 
                  const onChangefch = event =>{
                    setestadofch({ Fechavigencia: event.target.value }, () =>
                    console.log(this.state.Fechavigencia)
                    );
                  } 
                  const onChangelg = event =>{
                    setestadofch({ lugaentrega: event.target.value }, () =>
                    console.log(this.state.lugaentrega)
                    );
                  } 
                  const onChangeest = event =>{
                    setestadoest({ Estatussl: event.target.value }, () =>
                    console.log(this.state.Estatussl)
                    );
                  } 


                  const actualizar = () =>{
                    const LoadingMsg = () => (
                      <div>
                       <i className="fa fa-bell-o" ></i><strong className="ml-2">{"Cargando..."}</strong>    {""}
                      </div>
                    )
                    if(estadorfq.RFQsl == seleccionado.RFQsl && estadocat.Categoria == seleccionado.Categoria && estadocs.Cantidad_Solicitada == seleccionado.Cantidad_Solicitada && estadodesc.Descripcion == seleccionado.Descripcion && estadoemp.Empresa_solicitante == seleccionado.Empresa_solicitante && estadoum.Unidadmedida == seleccionado.Unidadmedida && estadomon.Moneda == seleccionado.Moneda && estadofch.Fechavigencia == seleccionado.Fechavigencia && estadolg.lugaentrega == seleccionado.lugaentrega && estadoest.Estatussl == seleccionado.Estatussl){
                      SweetAlert.fire({title:"ERROR", text:"No has escrito ningún cambio.", icon:"error"});  
                    }else{      
                      setmodal(false)
                      toast(<LoadingMsg />, {
                        position: toast.POSITION.TOP_RIGHT,
                        autoClose: 3000,
                        });
                      axios.put("https://b2bhubapi.com:8000/actualizarcotbuysel", {
                        RFQsl:estadorfq.RFQsl,
                        Emps: estadoemp.Empresa_solicitante,
                        Cat: estadocat.Categoria,
                        Desc: estadodesc.Descripcion,
                        Cants: estadocs.Cantidad_Solicitada,
                        Um: estadoum.Unidadmedida,
                        Mon: estadomon.Moneda,
                        Fch: estadofch.Fechavigencia,
                        Lg: estadolg.lugaentrega,
                        Est: estadoest.Estatussl,
                        id: seleccionado.id
                       }).then(res => {                        
                         SweetAlert.fire({
                          icon:"success",
                          title:"Datos actualizados correctamente.",    
                        }).then(async(result) => {
                                window.location.href = (`${process.env.PUBLIC_URL}/#/administrador/Cotizaciones`)
                                window.location.reload();
                          })
                       })                    
                      
                    }
                    
                  }

                  const eliminar = (data) =>{
                    SweetAlert.fire({
                      icon:"info",
                      title:"¿Estás seguro de eliminar esta cotización?, esto implica no tenerlo disponible nunca más", 
                      cancelButtonColor: "#0B2037",
                      cancelButtonText:"No estoy seguro, quiero volver",
                      confirmButtonText: "Eliminar",
                      reverseButtons: true,
                      showCancelButton: true,
                    }).then((result) => {
                      if(result.isConfirmed == true){
                        axios.delete('https://b2bhubapi.com:8000/dltcotbuysel', {
                         params: {
                         foo: data.id
                         }}      
                         ).then((response) => {
                          SweetAlert.fire({
                            icon:"success",
                            title:"Eliminado correctamente",    
                          }).then(async(result) => {
                                  window.location.href = (`${process.env.PUBLIC_URL}/#/administrador/Cotizaciones`)
                                  window.location.reload();
                            })
                        });

                      }
                    });
                  }


                  const actualizar2 = () =>{
                    const LoadingMsg = () => (
                      <div>
                       <i className="fa fa-bell-o" ></i><strong className="ml-2">{"Cargando..."}</strong>    {""}
                      </div>
                    )
                    if(estadorfq.RFQsl == seleccionado.RFQsl && estadocat.Categoria == seleccionado.Categoria && estadocs.Cantidad_Solicitada == seleccionado.Cantidad_Solicitada && estadodesc.Descripcion == seleccionado.Descripcion && estadoemp.Empresa_solicitante == seleccionado.Empresa_solicitante && estadoum.Unidadmedida == seleccionado.Unidadmedida && estadomon.Moneda == seleccionado.Moneda && estadofch.Fechavigencia == seleccionado.Fechavigencia && estadolg.lugaentrega == seleccionado.lugaentrega && estadoest.Estatussl == seleccionado.Estatussl){
                      SweetAlert.fire({title:"ERROR", text:"No has escrito ningún cambio.", icon:"error"});  
                    }else{      
                      setmodal2(false)
                      toast(<LoadingMsg />, {
                        position: toast.POSITION.TOP_RIGHT,
                        autoClose: 3000,
                        });
                      axios.put("https://b2bhubapi.com:8000/actualizarcotacep", {
                        RFQsl:estadorfq.RFQsl,
                        Emps: estadoemp.Empresa_solicitante,
                        Cat: estadocat.Categoria,
                        Desc: estadodesc.Descripcion,
                        Cants: estadocs.Cantidad_Solicitada,
                        Um: estadoum.Unidadmedida,
                        Mon: estadomon.Moneda,
                        Fch: estadofch.Fechavigencia,
                        Lg: estadolg.lugaentrega,
                        Est: estadoest.Estatussl,
                        id: seleccionado.id
                       }).then(res => {                        
                         SweetAlert.fire({
                          icon:"success",
                          title:"Datos actualizados correctamente.",    
                        }).then(async(result) => {
                                window.location.href = (`${process.env.PUBLIC_URL}/#/administrador/Cotizaciones`)
                                window.location.reload();
                          })
                       })                    
                      
                    }
                  }

                  const eliminar2 = (data) =>{
                    SweetAlert.fire({
                      icon:"info",
                      title:"¿Estás seguro de eliminar esta propuesta?, esto implica no tenerlo disponible nunca más", 
                      cancelButtonColor: "#0B2037",
                      cancelButtonText:"No estoy seguro, quiero volver",
                      confirmButtonText: "Eliminar",
                      reverseButtons: true,
                      showCancelButton: true,
                    }).then((result) => {
                      if(result.isConfirmed == true){
                        axios.delete('https://b2bhubapi.com:8000/dltcotacep', {
                         params: {
                         foo: data.id
                         }}      
                         ).then((response) => {
                          SweetAlert.fire({
                            icon:"success",
                            title:"Eliminado correctamente",    
                          }).then(async(result) => {
                                  window.location.href = (`${process.env.PUBLIC_URL}/#/administrador/Cotizaciones`)
                                  window.location.reload();
                            })
                        });

                      }
                    });
                  }



                  const actualizar3 = () =>{
                    const LoadingMsg = () => (
                      <div>
                       <i className="fa fa-bell-o" ></i><strong className="ml-2">{"Cargando..."}</strong>    {""}
                      </div>
                    )
                    if(estadorfq.RFQsl == seleccionado.RFQsl && estadocat.Categoria == seleccionado.Categoria && estadocs.Cantidad_Solicitada == seleccionado.Cantidad_Solicitada && estadodesc.Descripcion == seleccionado.Descripcion && estadoemp.Empresa_solicitante == seleccionado.Empresa_solicitante && estadoum.Unidadmedida == seleccionado.Unidadmedida && estadomon.Moneda == seleccionado.Moneda && estadofch.Fechavigencia == seleccionado.Fechavigencia && estadolg.lugaentrega == seleccionado.lugaentrega && estadoest.Estatussl == seleccionado.Estatussl){
                      SweetAlert.fire({title:"ERROR", text:"No has escrito ningún cambio.", icon:"error"});  
                    }else{      
                      setmodal3(false)
                      toast(<LoadingMsg />, {
                        position: toast.POSITION.TOP_RIGHT,
                        autoClose: 3000,
                        });
                      axios.put("https://b2bhubapi.com:8000/actualizargan", {
                        RFQsl:estadorfq.RFQsl,
                        Emps: estadoemp.Empresa_solicitante,
                        Cat: estadocat.Categoria,
                        Desc: estadodesc.Descripcion,
                        Cants: estadocs.Cantidad_Solicitada,
                        Um: estadoum.Unidadmedida,
                        Mon: estadomon.Moneda,
                        Fch: estadofch.Fechavigencia,
                        Lg: estadolg.lugaentrega,
                        Est: estadoest.Estatussl,
                        id: seleccionado.id
                       }).then(res => {                        
                         SweetAlert.fire({
                          icon:"success",
                          title:"Datos actualizados correctamente.",    
                        }).then(async(result) => {
                                window.location.href = (`${process.env.PUBLIC_URL}/#/administrador/Cotizaciones`)
                                window.location.reload();
                          })
                       })                    
                      
                    }
                  }

                  const eliminar3 = (data) =>{
                    SweetAlert.fire({
                      icon:"info",
                      title:"¿Estás seguro de eliminar este RFQ terminado?, esto implica eliminar un proceso completado, es muy riesgoso", 
                      cancelButtonColor: "#0B2037",
                      cancelButtonText:"No estoy seguro, quiero volver",
                      confirmButtonText: "Eliminar",
                      reverseButtons: true,
                      showCancelButton: true,
                    }).then((result) => {
                      if(result.isConfirmed == true){
                        axios.delete('https://b2bhubapi.com:8000/dltcotgan', {
                         params: {
                         foo: data.id
                         }}      
                         ).then((response) => {
                          SweetAlert.fire({
                            icon:"success",
                            title:"Eliminado correctamente",    
                          }).then(async(result) => {
                                  window.location.href = (`${process.env.PUBLIC_URL}/#/administrador/Cotizaciones`)
                                  window.location.reload();
                            })
                        });

                      }
                    });
                  }

    
    return (
      <Fragment>
        <Card>
        <CardHeader>
        <img className="pull-left" src={COTIZACIONES} style={{height: "35px"}} alt=""/>    
        </CardHeader>
        </Card> 
        <Col xl="6" className="xl-100 box-col-12">
                <Card>
                  <CardBody>
                    <Row className="r-hospital-chart">
                      <Col xl="4" md="4" sm="6">
                        <div className="media hospital-small-chart">
                          <div className="small-bar">
                            <div className="small-chart flot-chart-container">
                            <ChartistGraph data={operationSmallChart} listener={smallChartListener} options={hospitalSmallChartOptions} type={'Bar'} />
                            </div>
                          </div>
                          <div className="media-body">
                            <h3 className="d-inline-block f-w-600 m-l-10 mb-0">{numcotbuysel}</h3>
                            <h6 className="mb-0 f-w-600 m-l-10">{"Cotizaciones"}</h6>
                          </div>
                        </div>
                      </Col>
                      <Col xl="4" md="4" sm="6" className="b-l-light">
                        <div className="media hospital-small-chart">
                          <div className="small-bar">
                            <div className="small-chart1 flot-chart-container">
                            <ChartistGraph data={visitorSmallChart} listener={smallChartListener} options={hospitalSmallChartOptions} type={'Bar'} />
                            </div>
                          </div>
                          <div className="media-body">
                            <h3 className="d-inline-block f-w-600 m-l-10 mb-0">{numcotacep}</h3>
                            <h6 className="mb-0 f-w-600 m-l-10">{"Propuestas"}</h6>
                          </div>
                        </div>
                      </Col>
                      <Col xl="4" md="4" sm="12" className="b-l-light last-card">
                        <div className="media hospital-small-chart">
                          <div className="small-bar">
                            <div className="small-chart2 flot-chart-container">
                            <ChartistGraph data={medicineSmallChart} listener={smallChartListener} options={hospitalSmallChartOptions} type={'Bar'} />
                            </div>
                          </div>
                          <div className="media-body">
                            <h3 className="d-inline-block f-w-600 m-l-10 mb-0">{numcotganadores}</h3>
                            <h6 className="mb-0 f-w-600 m-l-10">{"Concluidos"}</h6>
                          </div>
                        </div>
                      </Col> 
                    </Row>
                  </CardBody>
                </Card>
              </Col>
              
        <Card>
                    <CardHeader>
                      <h5>{"Cotizaciones (compradores)"}</h5>
                    </CardHeader>
                    <CardBody className="p-0">
                      <div >
                      <Table >
                          <thead>
                            <tr>
                              <th scope="col">{"RFQ"}</th>
                              <th scope="col">{"Categoria"}</th>
                              <th scope="col">{"Descripción"}</th>
                              <th scope="col">{"Estado"}</th>
                              <th scope="col">{"Acciones"}</th>
                            </tr>
                          </thead>
                          <tbody>
                          {cotbuysel.map(data => (
                            <tr>
                              <td><span>{data.RFQsl}</span></td>
                              <td><span>{data.Categoria}</span></td>
                              <td><span>{data.Descripcion}</span></td>
                              <td><span>{data.Estatussl}</span></td>
                              <td><span className="badge badge-pill pill-badge-danger" style={{cursor:'pointer'}} onClick={() => abrirmodal(data)}>{"Editar"}</span></td>
                              <td><span className="badge badge-pill pill-badge-warning" style={{cursor:'pointer'}} onClick={() => eliminar(data)}>{"Eliminar"}</span></td>
                            </tr>
                            ))} 
                          </tbody>
                        </Table>
                      </div>
                    </CardBody>
                  </Card>
          <Row>         
            <Col lg="12" className="xl-100">
              <Row>
                
                
                <Col xl="8" className="xl-50 box-col-12">
                  <Card>
                    <CardHeader>
                      <h5>{"Propuestas (proveedores)"}</h5>        
                    </CardHeader>
                    <CardBody className="p-0">
                      <div className="sales-product-table table-responsive">
                      <Table>
                          <thead>
                            <tr>
                              <th scope="col">{"RFQ"}</th>
                              <th scope="col">{"Descripción"}</th>
                              <th scope="col">{"Cantidad solicitada"}</th>
                              <th scope="col">{"Acciones"}</th>
                            </tr>
                          </thead>
                          <tbody>
                          {cotacep.map(data => (
                            <tr>
                              <td><span>{data.RFQsl}</span></td>
                              <td><span>{data.Descripcion}</span></td>
                              <td><span>{data.Cantidad_Solicitada}</span></td>
                              <td><span className="badge badge-pill pill-badge-danger" style={{cursor:'pointer'}} onClick={() => abrirmodal2(data)}>{"Editar"}</span>
                              <span className="badge badge-pill pill-badge-warning" style={{cursor:'pointer'}} onClick={() => eliminar2(data)}>{"Eliminar"}</span></td>
                            </tr>
                            ))} 
                          </tbody>
                        </Table>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
  
                <Col xl="8" className="xl-50 box-col-12">
                  <Card>
                    <CardHeader>
                      <h5>{"Cotizaciones terminadas"}</h5> 
                    </CardHeader>
                    <CardBody className="p-0">
                      <div className="sales-product-table table-responsive">
                      <Table>
                          <thead>
                            <tr>
                              <th scope="col">{"RFQ"}</th>
                              <th scope="col">{"Descripción"}</th>
                              <th scope="col">{"Cantidad solicitada"}</th>
                              <th scope="col">{"Acciones"}</th>
                            </tr>
                          </thead>
                          <tbody>
                          {cotganadores.map(data => (
                            <tr>
                              <td><span>{data.RFQsl}</span></td>
                              <td><span>{data.Descripcion}</span></td>
                              <td><span>{data.Cantidad_Solicitada}</span></td>
                              <td><span className="badge badge-pill pill-badge-danger" style={{cursor:'pointer'}} onClick={() => abrirmodal3(data)}>{"Editar"}</span>
                              <span className="badge badge-pill pill-badge-warning" style={{cursor:'pointer'}} onClick={() => eliminar3(data)}>{"Eliminar"}</span></td>
                            </tr>
                            ))} 
                          </tbody>
                        </Table>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </Col>



            <Modal className="modal-lg modal-dialog-centered product-modal" isOpen={modal}>
            <ModalHeader className="d-inline-block f-w-600">{"Detalles de la cotización"}
                        </ModalHeader>
                    <ModalBody>
                        <ModalHeader>
                          <div className="product-box row">
                              <Col lg="6" className="product-img">
                                <Media className="img-fluid" src={box} alt=""/>
                              </Col>
                              <Col lg="6" className="product-details  text-left">
                                <div className="product-view">
                                <ScrollArea horizontal={true} vertical={true}>
                                <FormGroup>              
                                  <p className="mb-0">{"Nombre de la cotización:"}</p>
                                <Input className="form-control"  type="text" name="inputUsername" onChange={onChangerfq} value={estadorfq && estadorfq.RFQsl}autoComplete="on" />
                                 </FormGroup>
                                 <FormGroup>              
                                  <p className="mb-0">{"Empresa solicitante:"}</p>
                                <Input className="form-control"  type="text" name="inputUsername" onChange={onChangeemp} value={estadoemp && estadoemp.Empresa_solicitante}autoComplete="on" />
                                 </FormGroup>
                                 <FormGroup>              
                                  <p className="mb-0">{"Cantidad:"}</p>
                                <Input className="form-control"  type="number" name="inputUsername" onChange={onChangecans} value={estadocs && estadocs.Cantidad_Solicitada}autoComplete="on" />
                                 </FormGroup>           
                                 <FormGroup>
                                 <p className="mb-0">{"Fecha de vigencia de la cotización:"}</p>
                                    <Input type={"date"} onChange={onChangefch} value={estadofch && estadofch.Fechavigencia} />
                                </FormGroup> 
                                 <FormGroup>              
                                  <p className="mb-0">{"Categoria con codigo UNSPCS:"}</p>
                                <Input className="form-control" onChange={onChangecat} type="textarea"  name="inputUsername" value={estadocat && estadocat.Categoria}autoComplete="on" />
                                 </FormGroup>
                                 <FormGroup>              
                                  <p className="mb-0">{"Dirección de entrega:"}</p>
                                <Input className="form-control" onChange={onChangelg} type="textarea"  name="inputUsername" value={estadolg && estadolg.lugaentrega}autoComplete="on" />
                                 </FormGroup>
                                 <FormGroup>              
                                  <p className="mb-0">{"Descripción:"}</p>
                                <Input className="form-control" onChange={onChangedesc} type="textarea"  name="inputUsername" value={estadodesc && estadodesc.Descripcion}autoComplete="on" />
                                 </FormGroup>
                                 <FormGroup> 
                                 <p className="mb-0">{"Unidad de medida:"}</p>
                                <Input type="select" onChange={onChangeum} name="select" className="form-control btn-pill digits" id="exampleFormControlSelect7" value={estadoum && estadoum.Unidadmedida} >
                                <option>{""}</option>
                                <option>{"Pieza (PZA)"}</option>
                                <option>{"Kilogramo (KG)"}</option>
                                <option>{"Litro (LTO)"}</option>
                                <option>{"Tonelada (TON)"}</option>
                                <option>{"Galón (GL)"}</option> 
                                <option>{"Caja"}</option>
                                <option>{"Conjunto"}</option>
                                <option>{"Kit"}</option>
                                {/* <option>{"Unidad"}</option> */}
                                <option>{"Docena de piezas"}</option>     
                                <option>{"Lote"}</option>        
                                 </Input>
                                 </FormGroup>
                                 <FormGroup>
                                 <p className="mb-0">{"Moneda de cotización:"}</p>
                                <Input type="select" onChange={onChangemon} name="select" className="form-control btn-pill digits" id="exampleFormControlSelect7" value={estadomon && estadomon.Moneda}  >
                                <option>{""}</option>
                                <option>{"MXN($)"}</option>
                                <option>{"USD($)"}</option>
                                <option>{"EUR(€)"}</option>
                                <option>{"JPY(¥)"}</option>
                                <option>{"RMB(¥)"}</option>
                                </Input>                        
                                 </FormGroup>
                                 <FormGroup>
                                 <p className="mb-0">{"Estado:"}</p>
                                <Input type="select" onChange={onChangeest} name="select" className="form-control btn-pill digits" id="exampleFormControlSelect7" value={estadoest && estadoest.Estatussl}  >
                                <option>{""}</option>
                                <option>{"Normal"}</option>
                                <option>{"Urgente"}</option>
                                </Input>                        
                                 </FormGroup>
                                 </ScrollArea>
                                </div>
                              </Col>
                          </div>
                        </ModalHeader>
                      </ModalBody>       
                      <ModalFooter>
                        <Button color="primary" onClick={CerrarModal}>{"Cancelar y salir"}</Button>
                        <Button color="secondary" onClick={actualizar}>{"Actualizar"}</Button>
                        </ModalFooter>                   
                  </Modal>




                  <Modal className="modal-lg modal-dialog-centered product-modal" isOpen={modal2}>
            <ModalHeader className="d-inline-block f-w-600">{"Detalles de la cotización"}
                        </ModalHeader>
                    <ModalBody>
                        <ModalHeader>
                          <div className="product-box row">
                              <Col lg="6" className="product-img">
                                <Media className="img-fluid" src={box} alt=""/>
                              </Col>
                              <Col lg="6" className="product-details  text-left">
                                <div className="product-view">
                                <ScrollArea horizontal={true} vertical={true}>
                                <FormGroup>              
                                  <p className="mb-0">{"Nombre de la cotización:"}</p>
                                <Input className="form-control"  type="text" name="inputUsername" onChange={onChangerfq} value={estadorfq && estadorfq.RFQsl}autoComplete="on" />
                                 </FormGroup>
                                 <FormGroup>              
                                  <p className="mb-0">{"Empresa solicitante:"}</p>
                                <Input className="form-control"  type="text" name="inputUsername" onChange={onChangeemp} value={estadoemp && estadoemp.Empresa_solicitante}autoComplete="on" />
                                 </FormGroup>
                                 <FormGroup>              
                                  <p className="mb-0">{"Cantidad:"}</p>
                                <Input className="form-control"  type="number" name="inputUsername" onChange={onChangecans} value={estadocs && estadocs.Cantidad_Solicitada}autoComplete="on" />
                                 </FormGroup>           
                                 <FormGroup>
                                 <p className="mb-0">{"Fecha de vigencia de la cotización:"}</p>
                                    <Input type={"date"} onChange={onChangefch} value={estadofch && estadofch.Fechavigencia} />
                                </FormGroup> 
                                 <FormGroup>              
                                  <p className="mb-0">{"Categoria con codigo UNSPCS:"}</p>
                                <Input className="form-control" onChange={onChangecat} type="textarea"  name="inputUsername" value={estadocat && estadocat.Categoria}autoComplete="on" />
                                 </FormGroup>
                                 <FormGroup>              
                                  <p className="mb-0">{"Dirección de entrega:"}</p>
                                <Input className="form-control" onChange={onChangelg} type="textarea"  name="inputUsername" value={estadolg && estadolg.lugaentrega}autoComplete="on" />
                                 </FormGroup>
                                 <FormGroup>              
                                  <p className="mb-0">{"Descripción:"}</p>
                                <Input className="form-control" onChange={onChangedesc} type="textarea"  name="inputUsername" value={estadodesc && estadodesc.Descripcion}autoComplete="on" />
                                 </FormGroup>
                                 <FormGroup> 
                                 <p className="mb-0">{"Unidad de medida:"}</p>
                                <Input type="select" onChange={onChangeum} name="select" className="form-control btn-pill digits" id="exampleFormControlSelect7" value={estadoum && estadoum.Unidadmedida} >
                                <option>{""}</option>
                                <option>{"Pieza (PZA)"}</option>
                                <option>{"Kilogramo (KG)"}</option>
                                <option>{"Litro (LTO)"}</option>
                                <option>{"Tonelada (TON)"}</option>
                                <option>{"Galón (GL)"}</option> 
                                <option>{"Caja"}</option>
                                <option>{"Conjunto"}</option>
                                <option>{"Kit"}</option>
                                {/* <option>{"Unidad"}</option> */}
                                <option>{"Docena de piezas"}</option>     
                                <option>{"Lote"}</option>        
                                 </Input>
                                 </FormGroup>
                                 <FormGroup>
                                 <p className="mb-0">{"Moneda de cotización:"}</p>
                                <Input type="select" onChange={onChangemon} name="select" className="form-control btn-pill digits" id="exampleFormControlSelect7" value={estadomon && estadomon.Moneda}  >
                                <option>{""}</option>
                                <option>{"MXN($)"}</option>
                                <option>{"USD($)"}</option>
                                <option>{"EUR(€)"}</option>
                                <option>{"JPY(¥)"}</option>
                                <option>{"RMB(¥)"}</option>
                                </Input>                        
                                 </FormGroup>
                                 <FormGroup>
                                 <p className="mb-0">{"Estado:"}</p>
                                <Input type="select" onChange={onChangeest} name="select" className="form-control btn-pill digits" id="exampleFormControlSelect7" value={estadoest && estadoest.Estatussl}  >
                                <option>{""}</option>
                                <option>{"Normal"}</option>
                                <option>{"Urgente"}</option>
                                </Input>                        
                                 </FormGroup>
                                 </ScrollArea>
                                </div>
                              </Col>
                          </div>
                        </ModalHeader>
                      </ModalBody>       
                      <ModalFooter>
                        <Button color="primary" onClick={CerrarModal2}>{"Cancelar y salir"}</Button>
                        <Button color="secondary" onClick={actualizar2}>{"Actualizar"}</Button>
                        </ModalFooter>                   
                  </Modal>





                  <Modal className="modal-lg modal-dialog-centered product-modal" isOpen={modal3}>
            <ModalHeader className="d-inline-block f-w-600">{"Detalles de la cotización"}
                        </ModalHeader>
                    <ModalBody>
                        <ModalHeader>
                          <div className="product-box row">
                              <Col lg="6" className="product-img">
                                <Media className="img-fluid" src={box} alt=""/>
                              </Col>
                              <Col lg="6" className="product-details  text-left">
                                <div className="product-view">
                                <ScrollArea horizontal={true} vertical={true}>
                                <FormGroup>              
                                  <p className="mb-0">{"Nombre de la cotización:"}</p>
                                <Input className="form-control"  type="text" name="inputUsername" onChange={onChangerfq} value={estadorfq && estadorfq.RFQsl}autoComplete="on" />
                                 </FormGroup>
                                 <FormGroup>              
                                  <p className="mb-0">{"Empresa solicitante:"}</p>
                                <Input className="form-control"  type="text" name="inputUsername" onChange={onChangeemp} value={estadoemp && estadoemp.Empresa_solicitante}autoComplete="on" />
                                 </FormGroup>
                                 <FormGroup>              
                                  <p className="mb-0">{"Cantidad:"}</p>
                                <Input className="form-control"  type="number" name="inputUsername" onChange={onChangecans} value={estadocs && estadocs.Cantidad_Solicitada}autoComplete="on" />
                                 </FormGroup>           
                                 <FormGroup>
                                 <p className="mb-0">{"Fecha de vigencia de la cotización:"}</p>
                                    <Input type={"date"} onChange={onChangefch} value={estadofch && estadofch.Fechavigencia} />
                                </FormGroup> 
                                 <FormGroup>              
                                  <p className="mb-0">{"Categoria con codigo UNSPCS:"}</p>
                                <Input className="form-control" onChange={onChangecat} type="textarea"  name="inputUsername" value={estadocat && estadocat.Categoria}autoComplete="on" />
                                 </FormGroup>
                                 <FormGroup>              
                                  <p className="mb-0">{"Dirección de entrega:"}</p>
                                <Input className="form-control" onChange={onChangelg} type="textarea"  name="inputUsername" value={estadolg && estadolg.lugaentrega}autoComplete="on" />
                                 </FormGroup>
                                 <FormGroup>              
                                  <p className="mb-0">{"Descripción:"}</p>
                                <Input className="form-control" onChange={onChangedesc} type="textarea"  name="inputUsername" value={estadodesc && estadodesc.Descripcion}autoComplete="on" />
                                 </FormGroup>
                                 <FormGroup> 
                                 <p className="mb-0">{"Unidad de medida:"}</p>
                                <Input type="select" onChange={onChangeum} name="select" className="form-control btn-pill digits" id="exampleFormControlSelect7" value={estadoum && estadoum.Unidadmedida} >
                                <option>{""}</option>
                                <option>{"Pieza (PZA)"}</option>
                                <option>{"Kilogramo (KG)"}</option>
                                <option>{"Litro (LTO)"}</option>
                                <option>{"Tonelada (TON)"}</option>
                                <option>{"Galón (GL)"}</option> 
                                <option>{"Caja"}</option>
                                <option>{"Conjunto"}</option>
                                <option>{"Kit"}</option>
                                {/* <option>{"Unidad"}</option> */}
                                <option>{"Docena de piezas"}</option>     
                                <option>{"Lote"}</option>        
                                 </Input>
                                 </FormGroup>
                                 <FormGroup>
                                 <p className="mb-0">{"Moneda de cotización:"}</p>
                                <Input type="select" onChange={onChangemon} name="select" className="form-control btn-pill digits" id="exampleFormControlSelect7" value={estadomon && estadomon.Moneda}  >
                                <option>{""}</option>
                                <option>{"MXN($)"}</option>
                                <option>{"USD($)"}</option>
                                <option>{"EUR(€)"}</option>
                                <option>{"JPY(¥)"}</option>
                                <option>{"RMB(¥)"}</option>
                                </Input>                        
                                 </FormGroup>
                                 <FormGroup>
                                 <p className="mb-0">{"Estado:"}</p>
                                <Input type="select" onChange={onChangeest} name="select" className="form-control btn-pill digits" id="exampleFormControlSelect7" value={estadoest && estadoest.Estatussl}  >
                                <option>{""}</option>
                                <option>{"Normal"}</option>
                                <option>{"Urgente"}</option>
                                </Input>                        
                                 </FormGroup>
                                 </ScrollArea>
                                </div>
                              </Col>
                          </div>
                        </ModalHeader>
                      </ModalBody>       
                      <ModalFooter>
                        <Button color="primary" onClick={CerrarModal3}>{"Cancelar y salir"}</Button>
                        <Button color="secondary" onClick={actualizar3}>{"Actualizar"}</Button>
                        </ModalFooter>                   
                  </Modal>


          </Row>     
        </Fragment>
      
    )
  }
  
  export default Cotizaciones