import React, {useState,Fragment} from 'react'
import { Card, Button } from 'reactstrap'
import { ShoppingBag } from 'react-feather';
import { RFQS, RFQ , Recibidos, Aceptar } from "../../../constant"
import { Link } from 'react-router-dom';
import axios from 'axios';
import { useEffect } from 'react';

const URL ="https://c68f-189-190-147-29.ngrok.io/numeroenviados"
const getData = async () =>{
  const response = axios.get(URL);
  console.log(response);
  return response;
  
}

const RFQSRecibidosContadores = () => {
  const [list, setlist] = useState([])

  useEffect(() => {
    getData().then(( response ) =>{
      setlist(response.data);
  
    })
  }, [])

    return (
      <Fragment>
        <Card className="o-hidden">
              <div className="bg-secondary b-r-4 card-body">
                <div className="media static-top-widget">
                  <div className="align-self-center text-center"><ShoppingBag/></div>
                  <div className="media-body">
                    <h3>{RFQS} {Recibidos}</h3>
                    {list.map(data => (
                  <span className="mb-0 f-24">{data.Num}</span>
                  ))}
                    <ShoppingBag className="icon-bg"/>
                  </div>
                </div>
              </div>
            </Card>
      </Fragment>
    )
}

export default RFQSRecibidosContadores
