import React, {useState,Fragment, useEffect} from 'react'
import BreadCrumb from '../../layout/Breadcrumb'
import { Label, Input, Container, Row, Col, Card, CardBody, Media,CardHeader, Table, ModalHeader, ModalBody, ModalFooter, Modal , FormGroup ,Button} from 'reactstrap'
import {RFQS} from "../../constant";
import RFQSRecibidosContadores from './common/RFQSRecibidosContadores'
import {useSelector} from 'react-redux'
import axios from 'axios';
import { Link } from 'react-router-dom';
import ScrollArea from "react-scrollbar";
import SweetAlert from 'sweetalert2'
import { toast } from 'react-toastify';
import emailjs from 'emailjs-com';
import Tour from 'reactour'
import {disableBodyScroll,enableBodyScroll} from 'body-scroll-lock'
import DSH from "../../assets/images/blog/DASHBOARD2.png";
import { dark } from '@material-ui/core/styles/createPalette';
import { useFieldArray } from 'react-hook-form';
import ofr from '../../assets/images/ofr.png'

const steps = [
  {
      selector: '.spr1',
      content: 'Esta es la lista de cotizaciones creadas por los compradores con mi categoría de productos de venta',
  },
  {
      selector: '.spr2',
      content: 'Esta es la tabla de cotizaciones que he aceptado y enviado',
  },
  {
    selector: '.spr3',
    content: 'Acepta y espera la respuesta de un comprador',
}
]





const DashboardProveedor = (props) => {



  const [opentour,setopentour] = useState(false)
  const closeTour = () => {
      setopentour(false);
    };

    const tuto = () =>{
      setTimeout(() => {
        setopentour(true);
      }, 350);
    }

    const disableBody = target => disableBodyScroll(target);
    const enableBody = target => enableBodyScroll(target);



  // la lista que tengo que recibir todos los rfqs donde su categoria sea = a la categoria del currentuser
  const [modale, setModale] = useState();
  const [list, setlist] = useState([])
  const [seglist, setseglist] = useState([])
  const [rfqs, setrfqs] = useState([])
  const [data, setdata] = useState([])
  const [cargador, setcargador] = useState(false);
  const currentUser= useSelector(state => state.Common.currentUser)

  


  useEffect(()=>{
    const categorias = currentUser.Categoria.split(',');
    if(categorias.length > 1){
      let intoapi = []
      for (let i = 1; i < categorias.length; i++){
        intoapi.push(" OR (INSTR(Categoria," + "'"+ categorias[i] + "'" + ") > 0)") 
      }    
    axios.get('https://b2bhubapi.com:5050/proveedores', {
    params: {
      foo: "SELECT * from B2BUYSEL.RFqsbuysel WHERE " + "(INSTR(Categoria," + "'" + (categorias[0]) + "'" + ") > 0)" + intoapi.join("")
    }}      
    ).then((response) => {
      setlist(response.data)
      });
    }
    if(categorias.length == 1){
       axios.get('https://b2bhubapi.com:5050/proveedores', {
    params: {
      foo: "SELECT * from B2BUYSEL.RFqsbuysel WHERE " + "(INSTR(Categoria," + "'" + (categorias[0]) + "'" + ") > 0)" 
    }}      
    ).then((response) => {
        setlist(response.data);
      });
    }
},[])


useEffect(()=>{
  axios.get('https://b2bhubapi.com:5050/provmisacep', {
    params: {
      foo: currentUser.id
    }}      
    ).then((response) => {
        setseglist(response.data);
        if(response.data.length == 1){
          rfqs.push(response.data[0].RFQsl)
        }
        if(response.data.length > 1){
          for (let i = 0; i < response.data.length; i++){
            rfqs.push(response.data[i].RFQsl)
          }
        }
      });

},[])



// for (let i = 0; i < rfqs.length; i++){
//   data.push((list.find(c => c.RFQsl == rfqs[i])))
//  }
 


const [seleccionado, setseleccionado] = useState({
  RFQsl:'',
  Empresa_solicitante:'',
  Categoria:'',
  Descripcion:'',
  Cantidad_Solicitada:'',
  Unidadmedida:'',
  Precioobj:'',
  diasdecredito:'', 
  Moneda:'', 
  Fechavigencia:'',
  lugaentrega:'',
  Estatussl:'',
  iduc:''
});
const toggle = (data) => {
  let esono = rfqs.find(c => c == data.RFQsl)
  if(esono == null){
    setseleccionado(data)
    setModale(!modale)
  }if(esono.length >= 1){
    SweetAlert.fire({title:"Ya ha respondido esta cotización", text:"Su propuesta esta siendo analizada por el creador de la cotización", icon:"error"});
  }
 }
const [pre, setpre] = useState("");
const [tip, setip] = useState("")
const tiempoTranscurrido = Date.now();
      const hoy = new Date(tiempoTranscurrido)
      var el = hoy.getDate().toString();
      if (el.length == 1){ //example if 1 change to 01
      el = "0"+ el;
      }
      var getMonth = (hoy.getMonth()+1).toString();
      if (getMonth.length == 1){
      getMonth = "0"+getMonth;
      }
      var getYear = hoy.getFullYear().toString();
      var fecha = getYear + "-" + getMonth + "-" + el;
      var hora = hoy.getHours() + ':' + hoy.getMinutes();
 
 const enviar = () => { 
  if(pre == ""){
    SweetAlert.fire({title:"No has asignado un precio a la cotización", text:"Asigna un precio para entrar a la oferta.", icon:"error"});
  }else{
    setcargador(true)
    axios.post('https://b2bhubapi.com:3001/aceptarprov',
    {RFQsl:seleccionado.RFQsl,
    Empresa_solicitante:seleccionado.Empresa_solicitante,
    Categoria:seleccionado.Categoria,
    Descripcion:seleccionado.Descripcion,
    Cantidad_Solicitada:seleccionado.Cantidad_Solicitada,
    Unidadmedida:seleccionado.Unidadmedida,
    Precioobj:pre,
    diasdecredito:seleccionado.diasdecredito, 
    Moneda:seleccionado.Moneda, 
    Fechavigencia:seleccionado.Fechavigencia, 
    lugaentrega:seleccionado.lugaentrega, 
    Estatussl:seleccionado.Estatussl,
    Empresa_interesada:currentUser.Empresa,
    idui:currentUser.id,
    iduc:seleccionado.iduc,
    Fechadecreacion:fecha,
    }).then((response) => {
      axios.get('https://b2bhubapi.com:3001/usuariosanotificar2', { 
   params: {
    foo: seleccionado.iduc
  }}    
          ).then((response) => {
            if(response.data == "" && response.data == null){
              var usuariosanotificar = []
            }else{
              var usuariosanotificar = response.data
            }
            usuariosanotificar.forEach(usuario=>{
              var params = {
                fromName:currentUser.Nombre, 
                toName:usuario.Nombre, 
                toEmail:usuario.Correo,
               
              }
                emailjs.send('service_ruxs3u7', 'template_np892ov', params, 'user_6Yiy6j6dBtbUgO2vEhYPP').then((res)=>{
                  setcargador(false)
                  SweetAlert.fire({title:"Completado", 
                  text:"Has aceptado este RFQ, se ha enviado un Email al comprador, espera una respuesta pronto", 
                  icon:"success",
                  confirmButtonText: "Ok",}).then(async(result) => {
                    if(result.isConfirmed == true){
                      window.location.reload();  
                    }
                  });
                })
            })
    });
  });
  setModale(!modale)
  }
};
const [modale2, setModale2] = useState();
const toggle2 = (data) => {
  setseleccionado(data)
  setModale2(!modale2) 
  
 }
const eliminar = () => { 
    setcargador(true)
    axios.delete('https://b2bhubapi.com:3001/dltaceptados', {
      params: {
        foo: seleccionado.RFQsl
      }}      
      ).then((response) => {
        setcargador(false)
        SweetAlert.fire({title:"Completado", 
                text:"Se ha descartado tu propuesta de esta cotización", 
                icon:"success",
                confirmButtonText: "Ok",}).then(async(result) => {
                  if(result.isConfirmed == true){
                    window.location.reload();  
                  }
                });
              })
        setModale2(!modale2)
      }

    return (
      <Fragment>
<Card>
                <CardHeader>
                <img className="pull-left" src={DSH} style={{height: "35px"}} alt=""/>
                <Button  className="btn-pill pull-right" color="warning-gradien" size="lg"  onClick={tuto}>{"Tutorial"}</Button>
                </CardHeader>
              </Card>  
        <Container fluid={true}>
        <Tour
          steps={steps}
          rounded={5}
          isOpen={opentour}
          disableInteraction={true}
          disableKeyboardNavigation={false}
          onRequestClose={closeTour}
          onAfterOpen={disableBody}
          onBeforeClose={enableBody}
           />
          <Row>
            <Col xl="12" className="xl-100 box-col-12">
              <Card>
                <CardHeader>
                  <h5 className="mb-0 f-24">{"Lista de cotizaciones según mi categoría de producto/servicio"}</h5>
                  {/* <Button className="btn btn-success pull-right">{Descargar} {Lista}</Button> */}
                  
                </CardHeader>
                <Col >
                                </Col>
                <CardBody>
                  <div className="spr1 user-status table-responsive">
                    <Table borderless>
                      <thead>
                        <tr>
                          <th scope="col">{"RFQ"}</th>
                          <th scope="col">{"Empresa solicitante"}</th>
                          <th scope="col">{"Categoría"}</th>
                          <th scope="col">{"Descripción"}</th>
                          {/* <th scope="col">{"Cantidad solicitada"}</th>
                          <th scope="col">{"Unidad de medida"}</th> */}
                          {/* <th scope="col">{"Días de acreditación"}</th> */}
                          {/* <th scope="col">{"Moneda"}</th> */}
                          <th scope="col">{"Fecha de solicitud"}</th>
                          <th scope="col">{"Lugar de entrega"}</th>
                          <th scope="col">{"Estado"}</th>
                          <th scope="col">{"Acciones"}</th>
                        </tr>
                      </thead>
                      <tbody>
                      {list.map(data => (
                        <tr >
                        <td>{data.RFQsl}</td>
                        <td>{data.Empresa_solicitante}</td>
                        <td>{data.Categoria}</td>
                        <td>{data.Descripcion}</td>
                        {/* <td>{data.Cantidad_Solicitada}</td> */}
                        {/* <td>{data.Unidadmedida}</td> */}
                        {/* <td>{data.diasdecredito}</td> */}
                        {/* <td>{data.Moneda}</td> */}
                        <td>{data.Fechavigencia}</td>
                        <td>{data.lugaentrega}</td>
                        <td>
                          <div className={`span badge badge-pill badge-primary`}>{data.Estatussl}</div>
                        </td>
                        <td><Button onClick={() => toggle(data)} >{"Ofertar"}</Button></td> 
                         <Modal className="modal-lg modal-dialog-centered product-modal" isOpen={modale} toggle={toggle}  centered={true}>
                         <ModalHeader className="d-inline-block f-w-600" toggle={toggle}>{'Ofertar precio de producto'}
                        </ModalHeader>
                        <ModalBody>
                        <div className="product-box row">
                        <Col lg="6" className="product-details  text-left" style={{position: "relative",left: "9.5px"}}>
                        <div className="product-view">
                          <h5 className="d-inline-block f-w-600">{"Detalles de la cotización"}</h5>
                        <ScrollArea horizontal={true} vertical={true}>
                        <FormGroup>
                        <label> RFQ: </label>
                        <input className="form-control"  readOnly type="text" value={seleccionado && seleccionado.RFQsl} />
                        </FormGroup>
                        <FormGroup>
                        <label> Categoría: </label>
                        <input className="form-control"  readOnly type="text" value={seleccionado && seleccionado.Categoria}/>
                        </FormGroup>
                        <FormGroup>
                        <label> Empresa solicitante:</label>
                        <input className="form-control"  readOnly type="text" value={seleccionado && seleccionado.Empresa_solicitante}/>
                        </FormGroup>
                        <FormGroup>
                        <label> Producto solicitado: </label>
                        <input className="form-control"  readOnly type="text" value={seleccionado && seleccionado.Descripcion}/>
                        </FormGroup>
                        <FormGroup>
                        <label> Cantidad solicitada: </label>
                        <input className="form-control"  readOnly type="text" value={seleccionado && seleccionado.Cantidad_Solicitada}/>
                        </FormGroup>
                        <FormGroup>
                        <label> Fecha de vigencia: </label>
                        <input className="form-control"  readOnly type="text" value={seleccionado && seleccionado.Fechavigencia}/>
                        </FormGroup>
                        <FormGroup>
                        <label> Estado </label>
                        <input className="form-control"   readOnly type="text" value={seleccionado && seleccionado.Estatussl}/>
                        {/* añadir campo de attachment */}
                        </FormGroup>
                        </ScrollArea>
                        </div>
                         </Col> 
                         <Col lg="6" className="product-box row">
                        <label> Precio objetivo: </label>
                        <input className="form-control" type="number"  placeholder="$" onChange= {(e) => {setpre(e.target.value)}} />
                         <Media className="img-fluid" src={ofr} alt=""/>
                         </Col>
                         </div>
                        </ModalBody>
                        <ModalFooter>
                        <Button color="primary" onClick={toggle}>{'Cerrar'}</Button>
                        <Button color="secondary" onClick= {enviar}>{'Enviar oferta'}</Button>
                        
                        </ModalFooter>
                    </Modal>
                      </tr>
                      ))}                     
                      </tbody>
                   </Table>
                  </div>
                </CardBody>
                <Modal isOpen={cargador}  size="lg" style={{width:'530px'}} centered>
                        <ModalBody>
                        <Col md="12">
                      <div className="loader-box">
                        <div  className="loader-19"></div>
                      </div>
                    </Col>
                        </ModalBody>
                    </Modal>
                <CardHeader>
                  <h5 className="mb-0 f-24">{"Lista de mis ofertas enviadas"}</h5>
                </CardHeader>
                <CardBody>
                  <div className="spr2 user-status table-responsive">
                    <Table borderless>
                      <thead>
                        <tr>
                          <th scope="col">{"RFQ"}</th>
                          <th scope="col">{"Empresa solicitante"}</th>
                          <th scope="col">{"Categoría"}</th>
                          <th scope="col">{"Descripción"}</th>
                          {/* <th scope="col">{"Cantidad solicitada"}</th>
                          <th scope="col">{"Unidad de medida"}</th>
                          <th scope="col">{"Precio ofertado"}</th> */}
                          {/* <th scope="col">{"Dias de acreditación"}</th> */}
                          {/* <th scope="col">{"Moneda"}</th> */}
                          <th scope="col">{"Fecha de solicitud"}</th>
                          <th scope="col">{"Lugar de entrega"}</th>
                          <th scope="col">{"Mi oferta"}</th>
                          <th scope="col">{"Acciones"}</th>
                        </tr>
                      </thead>
                      <tbody>
                      {seglist.map(data => (
                        <tr >
                        <td>{data.RFQsl}</td>
                        <td>{data.Empresa_solicitante}</td>
                        <td>{data.Categoria}</td>
                        <td>{data.Descripcion}</td>
                        {/* <td>{data.Cantidad_Solicitada}</td>
                        <td>{data.Unidadmedida}</td>
                        <td>{data.Precioobj}</td> */}
                        {/* <td>{data.diasdecredito}</td> */}
                        {/* <td>{data.Moneda}</td> */}
                        <td>{data.Fechavigencia}</td>
                        <td>{data.lugaentrega}</td>
                        <td>
                          <div className={`span badge badge-pill badge-primary`}>{"$" + data.Precioobj}</div>
                        </td>
                        <td><Button color="primary" onClick={() => toggle2(data)} >{"Eliminar"}</Button></td>
                        <Modal isOpen={modale2} toggle2={toggle2} className="modal-body"  centered={true}>
                         <ModalHeader toggle2={toggle2}>{'¿Estás seguro de eliminar esta oferta?'}
                        </ModalHeader>
                        <ModalBody>
                        <FormGroup>
                        <label>
                 <h5> Si eliminas esta oferta, el comprador no la podrá ver</h5>                 
              </label>
                        </FormGroup>
                        </ModalBody>
                        <ModalFooter>
                        <Button color="primary" onClick={toggle2}>{'Cancelar'}</Button>
                        <Button color="success" className="sweet-8" name="alertSuccess" onClick= {eliminar}>{'Confirmar y eliminar'}</Button>                        
                        </ModalFooter>
                    </Modal>
                        </tr>
                      ))}                     
                      </tbody>
                   </Table>
                  </div>
                </CardBody>
                <CardHeader></CardHeader>
              </Card>
            </Col>
                        
          </Row>
        </Container>
        </Fragment>
    )
}

export default DashboardProveedor
