import React from 'react'
import { MessageSquare} from 'react-feather';
import BellDropdown from './BellDropdown'
import UserActivity from './UserActivity'
import DropletHeader from './Droplet'
import Search from './Search'
import {RightSidebarToggle} from '../../redux/common/actions'
import { useSelector, useDispatch } from 'react-redux';
import ForumOutlinedIcon from '@mui/icons-material/ForumOutlined';
const Rightbar = () => {
  const mobileRightTog = useSelector(state => state.Common.mobileRightToggle)
  const rightSidebarToggle = useSelector(state => state.Common.rightSidebarToggle)
  const dispatch = useDispatch();
  const currentUser= useSelector(state => state.Common.currentUser)

    const chat = () => {
      window.location.href = `${process.env.PUBLIC_URL}/#/Chat-app/chat`
    }

    return(
          <div className="nav-right col pull-right right-menu">

            <ul className={`nav-menus ${mobileRightTog ? 'open': ''}`}>

              <li> 
              <h6 className="mb-0">{currentUser.Nombre}</h6>
              <h10 className="mb-0">{currentUser.role}</h10>
              </li>
            
            {/* <li onClick={chat}><a  style={{cursor:'pointer'}}><ForumOutlinedIcon/></a></li> */}
            {/* <li>
                <BellDropdown/>
              </li> */}
             

               <UserActivity/>
              
            </ul>
          </div> 
    )
}


export default Rightbar