import React, { Fragment,useState,useEffect } from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import {firebase_app,auth0} from './data/config';
import * as serviceWorker from './serviceWorker';
import { Provider } from 'react-redux';
import { Auth0Provider } from '@auth0/auth0-react'
import store from './store/index'
import {HashRouter,Switch,Route,Redirect} from 'react-router-dom'
import { CSSTransition,TransitionGroup } from 'react-transition-group'
import App from './components/app';
import {routes} from './route/ContentRoutes'
import ScrollToTop from "./components/common/ScrollToTop";
import ConfigDB from './data/customizer/config'
import {configureFakeBackend ,authHeader, handleResponse} from './services/fack.backend'

// Signin page
import Signin from './auth/signin'
import Loginn from './auth/NuevoLogin/Log-in'

// BUYANDSELL 
// import General from '../src/components/widgets/General'
import Landingpage from '../src/Landing/Landingpage'
import Olvidastetucontraseña from '../src/Landing/Olvidastetucontraseña'
import RFQSList from '../src/components/widgets/RFQSList'
import dshprov from '../src/components/widgets/DashboardProveedor'
import dshcomp from '../src/components/widgets/DashboardComprador'
import crearrfq from '../src/components/widgets/CrearRFQ'

// Error page 
import Error400 from "./pages/errors/Error400";
import Error401 from "./pages/errors/Error401";
import Error403 from "./pages/errors/Error403";
import Error404 from "./pages/errors/Error404";
import Error500 from "./pages/errors/Error500";
import Error503 from "./pages/errors/Error503";


// Authentication
import Login from "./pages/authentication/Login";
import LoginWithBgImage from "./pages/authentication/LoginWithBgImage";
import LoginWithBgVideo from "./pages/authentication/LoginWithBgVideo";
import Register from "./pages/authentication/Register";
import RegisterWithBgImage from "./pages/authentication/RegisterWithBgImage";
import RegisterWithBgVideo from "./pages/authentication/RegisterWithBgVideo";
import UnlockUser from "./pages/authentication/UnlockUser";
import Forgetpwd from "./pages/authentication/Forgetpwd";
import Resetpwd from "./pages/authentication/Resetpwd";

// Comming soon
import Comingsoon from "./pages/comingSoon/Comingsoon";
import ComingsoonImg from "./pages/comingSoon/ComingsoonImg";
import ComingsoonVideo from "./pages/comingSoon/ComingsoonVideo";

// Maintenance
import Maintenance from "./pages/Maintenance";

import Callback from './auth/callback'


// setup fake backend
configureFakeBackend();



const Root = (props) =>  {
    
    const [anim, setAnim] = useState("");
    const animation = localStorage.getItem("animation") || ConfigDB.data.router_animation || 'fade'
    const abortController = new AbortController();
    const [currentUser, setCurrentUser] = useState([]);
    const [authenticated,setAuthenticated] = useState(false)
    const jwt_token = localStorage.getItem('token');

    useEffect(() => {

        const requestOptions = { method: 'GET', headers: authHeader() };
        fetch('/users', requestOptions).then(handleResponse)
        const color = localStorage.getItem('color')
        setAnim(animation)
        firebase_app.auth().onAuthStateChanged(setCurrentUser);
        setAuthenticated(JSON.parse(localStorage.getItem("authenticated")))
        console.ignoredYellowBox = ["Warning: Each", "Warning: Failed"];
        console.disableYellowBox = true;
        document.getElementById("color").setAttribute("href", `${process.env.PUBLIC_URL}/assets/css/${color}.css`);
        
        return function cleanup() {
            abortController.abort();
          }
        // eslint-disable-next-line
        }, []);
    return( 
        <Fragment>
            <Auth0Provider domain={auth0.domain} clientId={auth0.clientId} redirectUri={auth0.redirectUri}>
            <Provider store={store}>
                <HashRouter basename={`/`}>
                <ScrollToTop />
                  <Switch>
                      {/* login */}
                      <Route exact path={`${process.env.PUBLIC_URL}/`} component={Loginn} />
                      <Route exact path={`${process.env.PUBLIC_URL}/login`} component={Loginn} />
                      <Route exact path={`${process.env.PUBLIC_URL}/Landing/Landingpage`} component={Landingpage} />
                      <Route exact path={`${process.env.PUBLIC_URL}/Olvidastetucontraseña`} component={Olvidastetucontraseña} />
           
                      <Route  path={`${process.env.PUBLIC_URL}/callback`} render={() => <Callback/>} />
                      
                      {currentUser !== null || authenticated || jwt_token ?
                            <App>
                            
                            <Route exact path={`${process.env.PUBLIC_URL}/`} render={() => {
                                    return (<Redirect to={`${process.env.PUBLIC_URL}/`} />)
                                }} />
                            
                            <TransitionGroup>
                            {routes.map(({ path, Component }) => (
                                <Route key={path} exact   path={`${process.env.PUBLIC_URL}${path}`}>
                                    {({ match }) => (
                                        <CSSTransition 
                                        in={match != null}
                                        timeout={100}
                                        classNames={anim} 
                                        unmountOnExit
                                        >
                                        <div>
                                         
                                        <Component/></div>
                                        </CSSTransition> 
                                    )}
                                </Route>
                                ))}
                            </TransitionGroup>
                            </App>
                            :
                            <Redirect to={`${process.env.PUBLIC_URL}/`}  />
                        }
                  </Switch>
                </HashRouter>
            </Provider>
            </Auth0Provider>
        </Fragment>
    )
}
ReactDOM.render(<Root/>, document.getElementById('root'));
serviceWorker.unregister();
