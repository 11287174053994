import React, {useState,Fragment } from 'react'
import BreadCrumb from '../layout/Breadcrumb'
import ChartistGraph from 'react-chartist';
import { Container, Row, Col, Card, CardBody, CardHeader, Button,Label, Table, CardFooter, input, form, Input,Modal, ModalHeader, ModalBody, ModalFooter,Form,FormGroup} from 'reactstrap'
import { operationSmallChart,visitorSmallChart,medicineSmallChart, hospitalSmallChartOptions, smallChartListener, hospitalCurveChart, hospitalCurveChartOptions } from '../components/dashboard/chartsData/chartist'
import { apexBarChart, apexSmallChart } from '../components/dashboard/chartsData/apexChart'
import {doctorList, bookedAppoinment, patients} from '../components/dashboard/data'
import graph from '../assets/images/dashboard-sass/logo.png'
import box from '../assets/images/carton_box.png'
import LocalMallIcon from '@mui/icons-material/LocalMall';
import BusinessCenterIcon from '@mui/icons-material/BusinessCenter';
import { New,NewPatients,OPDPatients,Operations,Medicine,PatientIn,HospitalActivities,HospitalFinances,Profit,DoctorList,Visitors,Expensens,Details,SpecialistList,Message,BookedAppointment,Action,AdmitPatientList,Specialist} from "../constant";
import StepZilla from "react-stepzilla";
import Registration from '../components/forms/form-layout/form-wizard-1/registration';
import Email from '../components/forms/form-layout/form-wizard-1/email';
import Birthdate from '../components/forms/form-layout/form-wizard-1/birthdate';
import Checkbox from '@mui/material/Checkbox';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import SweetAlert from 'sweetalert2'
import Axios from 'axios';
import CategoryUnspc from '../components/widgets/CategoryUnspc'
import {firebase_app} from '../data/config'
import detallesxd from '../assets/images/detallesxd.png'

const Landingpage = () => {
  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const checkedIcon = <CheckBoxIcon fontSize="small" />;
  
    // eslint-disable-next-line
    const [doctorListState, setDoctorList] = useState(doctorList);
    // eslint-disable-next-line
    const [bookedAppoinmentState, setbookedAppoinment] = useState(bookedAppoinment);
    // eslint-disable-next-line
    const [admitPatientState, setadmitPatientState] = useState(patients);

    const steps =
    [
        { name: 'Step 1', component: <Registration /> },
        { name: 'Step 2', component: <Email /> },
        { name: 'Step 3', component: <Birthdate /> },
        
    ]

 const Provedores = [
        {
            id: 1,
            Nombre: "NORMA",
            Apellido: "MARQUEZ",
            Empresa: "SUMINISTROS DE IMPORTACION, SA DE CV",
            Numero: "5556127829",
            Categoria: "81141504 - REPARACION O CALIBRACION DE PRUEBAS DE EQUIPO",

            
        },
        {
            id: 2,
            Nombre: "JOSE REYES OROZCO",
            Empresa: "INOVA CONTROL SA DE CV",
            
        },
        {
            id: 3,
            Nombre: "DANIEL ARAUJO RAMÍREZ",
            Empresa: "CONEXIONES INDUSTRIALES Y TUBERIAS SA DE CV",
            
        },
        {
            id: 4,
            Nombre: "ALMA ALMANZA",
            Empresa: "ADHE MELT MEXICO SA DE CV",
            
        },
        {
            id: 5,
            Nombre: "WENDY RAMIREZ",
            Empresa: "PINTURAS DE MEXICO SA DE CV",
            
        },
        {
            id: 6,
            Nombre: "DANIELA LOPEZ",
            Empresa: "VISAPLAST SA DE CV",
            
        },
        {
            id: 7,
            Nombre: "MARIA JOSÉ CAMACHO",
            Empresa: "BIO VIN SA DE CV",
            
        },
        {
            id: 8,
            Nombre: "MAURICIO ROMAN TORRES",
            Empresa: "HERMOS SA DE CV",
            Apellido: "MARQUEZ",
            Numero: "5556127829",
            Categoria: "81141504 - REPARACION O CALIBRACION DE PRUEBAS DE EQUIPO",
            
        }

]
const RFQS = [
    {
        id: 1,
        RFQ: "225204-570 06N",
        EMP:"TRS050117RH3 - TRINITY RAIL SABINAS S DE RL D",
        PS:"TORNILLOS",
        CS:"23",
        ES:"Urgente"
    },
    {
        id: 2,
        RFQ: "225204-570 06N",
        EMP:"TRINITY RAIL SABINAS S DE RL D",
        PS:"TORNIQUETES",
        CS:"10333 PZ",
        ES:"Urgente"
        
    },
    {
        id: 3,
        RFQ: "225204-570 06N",
        EMP:"TRM041203E18 - TRINITY RAIL DE MEXICO DE RL D",
        PS:"SOLDADURA Y ADHESIVOS",
        CS:"20",
        ES:"Normal"
    },
    {
        id: 4,
        RFQ: "225204-570 06N",
        EMP:"TRM041203E18 - TRINITY RAIL DE MEXICO DE RL D",
        PS:"LAMINAS",
        CS:"200",
        ES:"Urgente"
        
    },
]

const funcionboton = () =>{

  window.location.href = `${process.env.PUBLIC_URL}/#/login`
  

}





  //COMPRADOR
  const [modal, setmodal] = useState(false);
  const [modal2, setmodal2] = useState(false);
  const [modal3, setmodal3] = useState(false);
  const [modal4, setmodal4] = useState(false);
  const [modal5, setmodal5] = useState(false);

  const [nomp, setnomp] = useState("") 
  const [catreg, setcatreg] = useState([])
  const [cantidad, setcantidad] = useState("")
  const [unimed, setunimed] = useState("")
  const [moncot, setmoncot] = useState("")
  const [fechv, setfechv] = useState("")
  const [ubicacion, setubicacion ] = useState("")
  const [desc, setdesc] = useState("")

  const [empr, setempr] = useState("")
  const [nume, setnume] = useState("")
  const [corr, setcorr] = useState("")
  const [contra1, setcontra1] = useState(".")
  const [contra2, setcontra2] = useState("")


  
const toggle = () => {
  setmodal(false)
  setnomp("")
}

const toggle2 = () => {
  setmodal2(false)
  setnomp("")
}

const cargador = () => {
  setmdet(false)
     setmodal(true)
     setTimeout(() => {
      setmodal(false)
      setmodal2(true)
    }, 1000);

}

  const toggle3 = () => {
    setmodal3(false)
    setnomp("")
  }
  const toggle4 = () => {
    setmodal4(false)
    setnomp("")
  }
  const toggle5 = () => {
    setmodal5(false)
    setnomp("")
  }

  
   const paso1 = () =>{
       
       if(nomp == ""){
        SweetAlert.fire({title:"Error", text:"Escribe en el campo para continuar", icon:"error"});        
      }
      else{
        setmodal(true)
        setTimeout(() => {     
          setmodal(false);
          setmodal2(false)
          setmodal3(true)
      }, 1000);

      }

   }

const paso2 = () =>{ 
 
  const cat = JSON.parse(localStorage.getItem('selectedc'))

  if(cat == null) {
    SweetAlert.fire({title:"No has seleccionado ninguna categoria", text:"Escribe en el campo para buscar una categoria ", icon:"error"});
  }else{
  
  cat.map(data =>{
    const opcion = data.code + " - " + data.esp
    setcatreg(opciones=>[...opciones, opcion])  
    
  })

  catreg.forEach(function(elemento, indice, array) {   
    
  })
  setmodal(true)

    setTimeout(() => {     
      setmodal(false);
      setmodal3(false)
      setmodal4(true)
      // setmodal5(true)
  }, 1000);
  
  }
}

const omitir = () => {
  SweetAlert.fire({
    icon:"info",
    title:"¿Estas seguro de omitir esta sección?, esto  implica romper el alcance de tu cotización", 
    cancelButtonColor: "#0B2037",
    cancelButtonText:"No estoy seguro, quiero volver",
    confirmButtonText: "Continuar sin categoria",
    reverseButtons: true,
    showCancelButton: true,
  }).then((result) => {
    if(result.isConfirmed == true){
      setmodal(true)
      setTimeout(() => {     
        setmodal(false);
        setmodal3(false)
        setmodal4(true)
        // setmodal5(true)
    }, 1000);
    }
  });
}




const paso3 = () =>{ 


if (cantidad == "", unimed == "", moncot == "", fechv == "", ubicacion =="", desc == "" ){
  SweetAlert.fire({title:"Completa los datos", text:"Llena todos los campos para continuar", icon:"error"});
}

else {
  setmodal(true)
  console.log(catreg.join())
  setTimeout(() => {     
    setmodal(false);
    setmodal4(false)
    setmodal5(true)
    // setmodal5(true)
}, 1000);

}
}


Axios.defaults.withCredencials = true;

  const tiempoTranscurrido = Date.now();
  const hoy = new Date(tiempoTranscurrido);
  var fecha = hoy.getDate() + '-' + ( hoy.getMonth() + 1 ) + '-' + hoy.getFullYear();
  var hora = hoy.getHours() + ':' + hoy.getMinutes() + ':' + hoy.getSeconds();
  const fechaYHora = 'Fecha: ' + fecha + ' ' + 'Hora: ' + hora;


  const [isuser, setisuser] = useState(localStorage.getItem("isUser"));


  const [res, setres] = useState("")

const crearcoti = () => {


    if(contra1 !== contra2){
      SweetAlert.fire({title:"Uppss", text:"Las contraseña no son correctas o no coinciden", icon:"error"});  
      if(empr == "", nume == "", corr == ""){
        SweetAlert.fire({title:"Completa los datos", text:"Llena todos los campos e intentalo nuevamente", icon:"error"});  
    }
   } 
   

  if(contra1 == contra2){
  setmodal(true)
  firebase_app.auth().createUserWithEmailAndPassword(corr, contra2).then(function () {
    
  })
  Axios.post('https://f0c4845c2c9c.ngrok.io/registro',
      {correo: corr, 
      password: contra2,
      numero: nume,
      nombre: empr,
      primerapellido:"",
      empresa: empr,
      role: "Comprador",
      categoria: catreg.join(),
      Fechadecreacion: fechaYHora,

    }).then(async(response) => {
      Axios.post('https://f0c4845c2c9c.ngrok.io/login',
      {correo: corr,
      password: contra2,}, 
    ).then((response) => { 
      sessionStorage.setItem('currentuser', JSON.stringify(response.data[0])) 
      Axios.post('https://70ae6ed1cdbe.ngrok.io/crearrfq', 
     
  {RFQsl:nomp,
  Empresa_solicitante:empr,
  Categoria:catreg.join(),
  Descripcion:desc,
  Cantidad_Solicitada:cantidad,
  Unidadmedida:unimed,
  Precioobj:"",
  diasdecredito:"", 
  Moneda:moncot, 
  Fechavigencia:fechv, 
  lugaentrega:ubicacion, 
  Estatussl:"Normal",
  iduc:response.data[0].id,
  // creadopor:currentuser.id
  }).then((response) => {
   

    setTimeout(() => {  
      setmodal(false);
      setmodal5(false)
    SweetAlert.fire({
      icon:"success",
      title:"Felicidades has creado tu cotización y ya eres parte de B2BuySel",    
      confirmButtonText: "Conoce nuestra nueva aplicación de cotizaciones, da click y empieza a hacer negocio",
      cancelButtonText:"Volver y seguir explorando",
      cancelButtonColor: "#6596CC",
      reverseButtons: true,
      showCancelButton: true,
    }).then(async(result) => {
      if(result.isConfirmed == true){
        await firebase_app.auth().signInWithEmailAndPassword(corr, contra2).then( (usercredential) => {            
          setTimeout(() => {
            setisuser("true");
            window.location.href = (`${process.env.PUBLIC_URL}/#/widgets/Bienvenida`)
            window.location.reload();
          }, 900);
        
        }) 
      }
    });
  }, 1000);
  })
     })

    })
}
}

//PROVEEDOR

const [modal11, setmodal11] = useState(false);
const [modal12, setmodal12] = useState(false);
const [mdet, setmdet] = useState(false);

const toggle11 = () => {
  setmodal11(false)
}
const toggle12 = () => {
  setmodal12(false)
}
const toggledet = () => {
  setmdet(false)
}

const paso11 = () => {
  
  setmodal(true)
  setTimeout(() => {
   setmodal(false)
   setmodal11(true)
 }, 1000);
}
const paso12 = () => {
  const cat = JSON.parse(localStorage.getItem('selectedc'))

  if(cat == null) {
    SweetAlert.fire({title:"No has seleccionado ninguna categoria", text:"Escribe en el campo para buscar una categoria ", icon:"error"});
  }

  else{
  
    cat.map(data =>{
      const opcion = data.code + " - " + data.esp
      setcatreg(opciones=>[...opciones, opcion])  
      
    })
  
    catreg.forEach(function(elemento, indice, array) {   
      
    })

  setmodal11(false)
  setmodal(true)
  setTimeout(() => {
   setmodal(false)
   setmodal12(true)
 }, 1000);
  }

}

const registr = () => {
  if(contra1 !== contra2){
    SweetAlert.fire({title:"Uppss", text:"Las contraseña no son correctas o no coinciden", icon:"error"});  
    if(empr == "", nume == "", corr == ""){
      SweetAlert.fire({title:"Completa los datos", text:"Llena todos los campos e intentalo nuevamente", icon:"error"});  
  }
 } 
 if(contra1 == contra2){
  setmodal(true)
  firebase_app.auth().createUserWithEmailAndPassword(corr, contra2).then(function () {
    
  })
  Axios.post('https://f0c4845c2c9c.ngrok.io/registro',
      {correo: corr, 
      password: contra2,
      numero: nume,
      nombre: empr,
      primerapellido:"",
      empresa: empr,
      role: "Proveedor",
      categoria: catreg.join(),
      Fechadecreacion: fechaYHora,

    }).then(async(response) => {
      Axios.post('https://f0c4845c2c9c.ngrok.io/login',
      {correo: corr,
      password: contra2,}, 
    ).then((response) => { 
      sessionStorage.setItem('currentuser', JSON.stringify(response.data[0]))
      setTimeout(() => {  
        setmodal(false);
        setmodal5(false)
      SweetAlert.fire({
        icon:"success",
        title:"Felicidades ya eres parte de B2BuySel",    
        confirmButtonText: "Entra ahora y oferta tus productos",
        cancelButtonText:"Volver y seguir explorando",
        cancelButtonColor: "#6596CC",
        reverseButtons: true,
        showCancelButton: true,
      }).then(async(result) => {
        if(result.isConfirmed == true){
          await firebase_app.auth().signInWithEmailAndPassword(corr, contra2).then( (usercredential) => {            
            setTimeout(() => {
              setisuser("true");
              window.location.href = (`${process.env.PUBLIC_URL}/#/widgets/Bienvenida`)
              window.location.reload();
            }, 900);
          
          }) 
        }
      });
    }, 1000);
     })

    })

  }

}
   

const detalles = () =>{
  setmodal(true)
  setTimeout(() => {
   setmodal(false)
   setmdet(true)
 }, 1000);
}


    return (
      <Fragment>
        <div className="card overview-color">
            <div className="card-body bg-gradient-primary text-center" style={{height:'100px'}}>    
            <img  style={{height:'45px',width:"200px", position:"relative", top:"-5.004px", left: "-419.508px"}} src={graph} alt=""/>
            <Button style={{height:'38px',width:"160px", position:"relative", top:"-3.50562px", left: "470.49px"}}className="btn-pill"  color="success-gradien" onClick={funcionboton} >{"Comienza Ahora"}</Button>     
            </div>
        </div>
        <Container fluid={true}>
            <Row>
              <Col xl="3" className="xl-50 col-6 hospital-patients box-col-6">
                <Card className="o-hidden">
                  <CardBody>
                    <div className="hospital-widgets media">
                      <div className="hospital-box light-bg-danger"><img src={"Heart"} alt=""/><LocalMallIcon/></div>
                      <div className="media-body">
                        <div className="hospital-content">
                          <h3 className="d-inline-block f-w-600">{"¿Eres comprador?"}</h3>
                          <h6 className="m-t-5 mb-0 f-w-600">{"Realiza una cotización ahora"}</h6>
                        </div>
                        <Button  onClick={cargador} className="btn-pill" outline color="danger" size="sm"style={{position:"relative",left: "334.992px", height: "45px", width: "150px"}} >{"Cotizar"}</Button>
                        <Modal isOpen={modal} toggle={toggle} size="lg" style={{width:'530px'}} centered>
                        <ModalBody>
                        <Col md="12">
                      <div className="loader-box">
                        <div  className="loader-19"></div>
                      </div>
                    </Col>
                        </ModalBody>
                    </Modal>
                    <Modal isOpen={modal2} toggle2={toggle2} size="lg" centered>
                        <ModalBody>
                        <h3 className="d-inline-block f-w-600">{"¿Que producto te interesa?"}</h3>
                        <font color="#FFFFFF">
                            <h1>{'|'}</h1>
                                                    <Col md="12">
                          <FormGroup>
                            <Input className="form-control"  onChange= {(e) => {setnomp(e.target.value)}} placeholder="Ejemplo: Tornillos"/>                                
                          </FormGroup>
                        </Col>
                            <h2>{'|'}</h2>
                                </font>
                                <img  style={{width:'200px', height: '200px',position: 'relative' ,left: '285px'}} src={box} alt=""/>
                        </ModalBody>
                        <ModalFooter>
                        <Button color="primary" onClick={toggle2}>{"Cancelar"}</Button>
                        <Button color="secondary" onClick={paso1}>{"Siguiente"}</Button>
                        </ModalFooter>
                    </Modal>
                    <Modal isOpen={modal3} toggle={toggle3} size="lg"  centered>
                        <ModalHeader toggle3={toggle3}className="d-inline-block f-w-600">{"Elige una categoria para llegar a mas proveedores"}
                        </ModalHeader>
                    <ModalBody>
                    <CategoryUnspc/>
                        </ModalBody>
                        <ModalFooter>                  
                        <Button color="secondary" onClick={omitir}>{"Omitir"}</Button>
                        <Button color="primary" onClick={toggle3}>{"Cancelar"}</Button>
                        <Button color="secondary" onClick={paso2}>{"Siguiente"}</Button>
                        </ModalFooter>
                    </Modal>


                    <Modal isOpen={modal4} toggle={toggle4} size="lg" centered>
                        <ModalBody>
                        <h3 className="d-inline-block f-w-600">{"Proporcionanos más detalles de tu Cotización"}</h3>
                        <font color="#FFFFFF">
                            <h2>{'|'}</h2>
                                </font>
                        <FormGroup>
                      <Label className="col-form-label">{"Cantidad"}</Label>
                      <Input className="form-control" type="number"  onChange= {(e) => {setcantidad(e.target.value)}} />
                    </FormGroup>
                    <FormGroup>
                    <Label className="col-form-label pt-0">{'Unidad de medida'}</Label>
                        <Input onChange= {(e) => {setunimed(e.target.value)}}type="select" name="select" className="form-control btn-pill digits" id="exampleFormControlSelect7"  >
                        <option>{""}</option>
                          <option>{"Pieza (PZA)"}</option>
                          <option>{"Kilogramo (KG)"}</option>
                          <option>{"Litro (LTO)"}</option>
                          <option>{"Tonelada (TON)"}</option>
                          <option>{"Galón (GL)"}</option> 
                          <option>{"Caja"}</option>
                          <option>{"Conjunto"}</option>
                          <option>{"Kit"}</option>
                          <option>{"Unidad"}</option>
                          <option>{"Docena de piezas"}</option>     
                          <option>{"Lote"}</option>        
                        </Input>
                    </FormGroup>
                    <FormGroup>
                    <Label className="col-form-label pt-0">{'Moneda de Cotización'}</Label>
                        <Input onChange= {(e) => {setmoncot(e.target.value)}} type="select" name="select" className="form-control btn-pill digits" id="exampleFormControlSelect7"  >
                        <option>{""}</option>
                          <option>{"MXN"}</option>
                          <option>{"USD($)"}</option>
                          <option>{"EUR(€)"}</option>
                          <option>{"JPY(¥)"}</option>
                          <option>{"(RMB)"}</option>
                        </Input>                        
                    </FormGroup>
                    <FormGroup>
                    <Label className="col-form-label pt-0">{"Fecha de vigencia de la cotización"}</Label>
                        <Input onChange= {(e) => {setfechv(e.target.value)}} type={"date"} placeholder={""} />
                    </FormGroup>  
                    <FormGroup>
                    <Label className="col-form-label pt-0">{"Ubicacion de Entrega"}</Label>
                        <Input onChange= {(e) => {setubicacion(e.target.value)}} type={"address"}  /> 
                    </FormGroup>  
                    <FormGroup>
                    <Label className="col-form-label pt-0">{'Descripción detallada'}</Label>
                        <Input onChange= {(e) => {setdesc(e.target.value)}}type="textarea" className="form-control" />
                    </FormGroup> 
                        </ModalBody>
                        <ModalFooter>
                        <Button color="primary" onClick={toggle4}>{"Cancelar"}</Button>
                        <Button color="secondary" onClick={paso3}>{"Siguiente"}</Button>
                        </ModalFooter>
                    </Modal>
                    <Modal isOpen={modal5} toggle5={toggle5} size="lg" centered>
                        <ModalBody>
                        <h3 className="d-inline-block f-w-600">{"Ya estamos cerca..."}</h3>
                        
                        <font color="#FFFFFF">
                            <h4>{'|'}</h4>
                                </font>
                               
                                <h5 className="d-inline-block f-w-600">{"Proporciona tus datos de contacto"}</h5>
                                <font color="#FFFFFF">
                            <h4>{'|'}</h4>
                                </font>
                                <Col className="was-validated">
                                <FormGroup>
                    <Label className="col-form-label pt-0">{"¿Cual es tu empresa?"}</Label>
                        <Input onChange= {(e) => {setempr(e.target.value)}} className="form-control" required/>
                    </FormGroup>
                     </Col>
                    <Col className="was-validated">
                    <FormGroup>
                    <Label className="col-form-label pt-0">{'¿Cual es tu numero de contacto?'}</Label>
                        <Input className="form-control" inputmode="numeric" pattern="[0-9]{10}"  onChange= {(e) => {setnume(e.target.value)}} placeholder={" digite 10 numeros"} required/>
                    </FormGroup> 
                    </Col>
                    <Col md="12" className="was-validated">
                    <FormGroup>
                    <Label className="col-form-label pt-0">{'Correo Electronico'}</Label>
                        <Input  type="email" pattern="[^@\s]+@[^@\s]+" onChange= {(e) => {setcorr(e.target.value)}} className="form-control"  required/>
                    </FormGroup> 
                    </Col>
                    <Col md="12" className="was-validated">
                    <FormGroup>
                    <Label className="col-form-label pt-0">{'Crea una contraseña'}</Label>
                    <Input className="form-control" type="password" onChange= {(e) => {setcontra1(e.target.value)}}placeholder="Contraseña (de 8 a mas caracteres)" minlength="8"required />              
                      </FormGroup> 
                      </Col>          
                      <Col md="12" className="was-validated">     
                    <FormGroup>
                      <Input className="form-control" type="password" onChange= {(e) => {setcontra2(e.target.value)}}placeholder="Contraseña (de 8 a mas caracteres)" minlength="8"required  />
                      </FormGroup>
                      </Col>    
                        </ModalBody>
                        <ModalFooter>
                        <Button color="primary" onClick={toggle5}>{"Cancelar"}</Button>
                        <Button color="secondary" onClick={crearcoti}>{"Crear Cotización"}</Button>
                        </ModalFooter>
                    </Modal>
                        <div className="flowers">
                          <div className="flower1"></div>
                          <div className="flower2"></div>
                          <div className="flower3"></div>
                        </div>
                      </div>
                    </div>
                  </CardBody>
                </Card>
              </Col>
              <Col xl="3" className="xl-50 col-6 hospital-patients box-col-6">
                <Card className="o-hidden">
                  <CardBody>
                    <div className="hospital-widgets media">
                      <div className="hospital-box light-bg-primary"><img src={"Brain"} alt=""/><BusinessCenterIcon/></div>
                      <div className="media-body">
                        <div className="hospital-content">
                          <h3 className="d-inline-block f-w-600">{"¿Eres proveedor?"}</h3>
                          <h6 className="m-t-5 mb-0 f-w-600">{"Vende tus productos ahora"}</h6>
                        </div>
                        <Button className="btn-pill" outline color="warning" size="sm" onClick={paso11} style={{position:"relative",left: "348.998px", height: "45px", width: "130px"}}>{"Vender"}</Button>
                        <Modal isOpen={modal11} toggle={toggle11} size="lg"  centered>
                        <ModalHeader className="d-inline-block f-w-600">{"¿Que productos vendo?"}
                        </ModalHeader>
                    <ModalBody>
                    <h6 >{"Busca entre las categorias, elige la que mejor se adapte a tus ventas"}</h6>
                    <font color="#FFFFFF">
                            <h6>{'|'}</h6>
                                </font>
                    <CategoryUnspc/>
                        </ModalBody>
                        <ModalFooter>                  
                        <Button color="primary" onClick={toggle11}>{"Cancelar"}</Button>
                        <Button color="secondary" onClick={paso12}>{"Siguiente"}</Button>
                        </ModalFooter>
                    </Modal>
                    <Modal isOpen={modal12} toggle={toggle12} size="lg" centered>
                        <ModalBody>
                        <h3 className="d-inline-block f-w-600">{"Te necesitamos en B2BuySel..."}</h3>
                        
                        <font color="#FFFFFF">
                            <h4>{'|'}</h4>
                                </font>
                                <h5 className="d-inline-block f-w-600">{"Conoce nuestra aplicación y oferta tus productos, registrate ahora"}</h5>
                                <font color="#FFFFFF">
                            <h4>{'|'}</h4>
                                </font>
                                <Col className="was-validated">
                                <FormGroup>
                    <Label className="col-form-label pt-0">{"¿Cual es tu empresa?"}</Label>
                        <Input onChange= {(e) => {setempr(e.target.value)}} className="form-control" required/>
                    </FormGroup>
                     </Col>
                    <Col className="was-validated">
                    <FormGroup>
                    <Label className="col-form-label pt-0">{'¿Cual es tu numero de contacto?'}</Label>
                        <Input className="form-control" inputmode="numeric" pattern="[0-9]{10}"  onChange= {(e) => {setnume(e.target.value)}} placeholder={" digite 10 numeros"} required/>
                    </FormGroup> 
                    </Col>
                    <Col md="12" className="was-validated">
                    <FormGroup>
                    <Label className="col-form-label pt-0">{'Correo Electronico'}</Label>
                        <Input  type="email" pattern="[^@\s]+@[^@\s]+" onChange= {(e) => {setcorr(e.target.value)}} className="form-control"  required/>
                    </FormGroup> 
                    </Col>
                    <Col md="12" className="was-validated">
                    <FormGroup>
                    <Label className="col-form-label pt-0">{'Crea una contraseña'}</Label>
                    <Input className="form-control" type="password" onChange= {(e) => {setcontra1(e.target.value)}}placeholder="Contraseña (de 8 a mas caracteres)" minlength="8"required />              
                      </FormGroup> 
                      </Col>          
                      <Col md="12" className="was-validated">     
                    <FormGroup>
                      <Input className="form-control" type="password" onChange= {(e) => {setcontra2(e.target.value)}}placeholder="Contraseña (de 8 a mas caracteres)" minlength="8"required  />
                      </FormGroup>
                      </Col>    
                        </ModalBody>
                        <ModalFooter>
                        <Button color="primary" onClick={toggle12}>{"Cancelar"}</Button>
                        <Button color="secondary" onClick={registr}>{"Registrarse"}</Button>
                        </ModalFooter>
                    </Modal>

                        <div className="flowers">
                          <div className="flower1 flower-primary"></div>
                          <div className="flower2 flower-primary"></div>
                          <div className="flower3 flower-primary"></div>
                        </div>
                      </div>
                    </div>
                </CardBody>
              </Card>
              </Col>
                            
              <Col xl="4" className="xl-50 box-col-6">
                <Card className="height-equal">
                  <CardHeader className="card-header">
                    <h5>{"Provedores disponibles"}</h5>
                  </CardHeader>
                  <CardBody className="card-body p-0">
                    <div className="sales-product-table crypto-table-market sass-table table-responsive">
                      <Table borderless>
                        <tbody>
                          {Provedores.map(data =>(
                                 <tr key={data.id}>
                                 <td>
                                   <div className="media"><img className="img-fluid rounded-circle img-40 mr-3" src={"data.imagepath"} alt=""/>
                                     <div className="media-body"><span className="f-w-600 d-block">{data.Nombre}</span></div>
                                   </div>
                                 </td>
                                 <td className="text-center"><span className="f-w-600 d-block">{data.Empresa}</span></td>
                                 <td>
                                 <Button color="light" outline className="txt-dark" onClick={detalles}>{"Detalles"}</Button>
                                
                                 </td>
                               </tr>
                          ))}
                        </tbody> 
                      </Table>
                    </div>
                  </CardBody>
                </Card>
              </Col>  
              <Modal isOpen={mdet} toggle={toggledet} size="lg"  centered>
                        <ModalHeader className="d-inline-block f-w-600">{"Detalles de contacto"}
                        </ModalHeader>
                    <ModalBody>
                    <img   src={detallesxd} alt=""/>
                    <font color="#FFFFFF">
                            <h4>{'|'}</h4>
                                </font>
                    <h6>{"Esta información es sensurada para el publico en general pero se parte de B2buySel y empieza a cotizar, podras ver esta y mas información"}</h6>
                        </ModalBody>
                        <ModalFooter>
                        <Button color="primary" onClick={toggledet}>{"Cancelar"}</Button>
                        <Button color="secondary" onClick={cargador}>{"Cotizar"}</Button>
                        </ModalFooter>
                    </Modal>

              <Col xl="7" className="xl-50 box-col-12">
                <Card>
                  <CardHeader>
                    <h5>{"Productos solicitados"}</h5>
                  </CardHeader>
                  <CardBody className="p-0">
                    <div className="sales-product-table table-responsive booked-table">
                      <Table borderless className="text-center">
                        <thead>
                          <tr>                            
                              <th scope="col">{"id"}</th>
                            <th scope="col">{"RFQ"}</th>
                            <th scope="col">{"Empresa"}</th>
                            <th scope="col">{"Producto"}</th>
                            <th scope="col">{"Cantidad"}</th>
                            <th scope="col">{"Estado"}</th>
                          </tr>
                        </thead>
                        <tbody>
                          {RFQS.map(data => (
                            <tr key={data.id}>
                              <td>{data.id}</td>
                              <td>
                                <div className="d-inline-block align-middle">
                                  <div className="d-inline-block"><span className="f-w-600">{data.RFQ}</span></div>
                                </div>
                              </td>
                              <td><span>{data.EMP}</span></td>
                              <td><span>{data.PS}</span></td>
                              <td><span>{data.CS}</span></td>
                              <td><span className={`badge badge-pill pill-badge-${data.className}`}>{data.ES}</span></td>
                          </tr>
                          ))}
                        </tbody>
                      </Table>
                    </div>
                  </CardBody>
                </Card>
              </Col>
                         
             
              <Col xl="4" className="xl-100 box-col-6">
                <Card className="bg-gradient-primary hospital-bg">
                  <CardHeader>
                    <h5>{"B2Buysel Finanzas"}</h5>
                  </CardHeader>
                  <CardBody className="p-0">
                    <div className="hospital-chartist">
                      <div className="hospital-curve">
                      <ChartistGraph data={hospitalCurveChart} options={hospitalCurveChartOptions} type={'Line'} />
                      </div>
                      <div className="hospital-pin"><img src={"Pill"} alt=""/>
                        <div className="hospital-profit">
                          <i className="fa fa-circle-o p-r-10"></i>
                        <span className="pr-4">{"Ventas"}  </span>
                        <i className="fa fa-database p-r-10"></i><span className="p-r-10">{"Compras"}  </span></div>
                      </div>
                    </div>
                  </CardBody>
                </Card>
              </Col>
             
            </Row>           
            </Container>
            </Fragment>
    )
}

export default Landingpage
