import React, {useState,Fragment, useEffect} from 'react'
import { Label, Container, Row, Col, Card, CardBody, CardHeader, Button, Table,ModalHeader, ModalBody, ModalFooter, Modal, FormGroup, Input} from 'reactstrap'
import {useSelector} from 'react-redux'
import axios from 'axios';
import Axios from 'axios';
import { toast } from 'react-toastify';
import emailjs from 'emailjs-com'
import {PearlsStepStates} from "../advance/Steps/StepsComponent";
import Tour from 'reactour'
import {disableBodyScroll,enableBodyScroll} from 'body-scroll-lock'
import DSH from "../../assets/images/blog/DASHBOARD.png";
import box from '../../assets/images/carton_box.png'
import entrega_box from '../../assets/images/entrega_box.png'
import AddchartIcon from '@mui/icons-material/Addchart';
import SweetAlert from 'sweetalert2'
import CategoryUnspc from '../widgets/CategoryUnspc'
import Geocode from "react-geocode";

Geocode.setApiKey("AIzaSyBvH0gOVYBWudUmzRGairJyLO7clTrHtIo");
Geocode.enableDebug();


const pearlsstepstates =[{name: 'Step 9',component:<PearlsStepStates/>}]

const steps = [
  {
      selector: '.paso1',
      content: 'Pulsa este boton para crear un RFQ',
  },
  {
      selector: '.paso2',
      content: 'Aqui encontraremos la lista de nuestros RFQs creados',
  },
  {
    selector: '.paso3',
    content: 'Esta es la lista de RFQs que hemos asignado ',
}

]



const DashboardComprador = (props) => {
  //tour
  const [opentour,setopentour] = useState(false)
  const closeTour = () => {
      setopentour(false);
    };

    const tuto = () =>{
      setTimeout(() => {
        setopentour(true);
      }, 350);
    }

    const disableBody = target => disableBodyScroll(target);
    const enableBody = target => enableBodyScroll(target);

//lodemas

  const [modale, setModale] = useState();
  const [modale2, setModale2] = useState();
  const [cargador, setcargador] = useState();
  const [masig, setmasig] = useState();
  const [list, setlist] = useState([])
  const [seglist, setseglist] = useState([])
  const [empresa, setempresa] = useState("");
  const [terlist, setterlist] = useState([]);
  
  const currentUser= useSelector(state => state.Common.currentUser)

   

  useEffect(() => {
    axios.get('https://b2bhubapi.com:7575/asig', {
      params: {
        foo: currentUser.id
      }}      
      ).then((response) => {
          setterlist(response.data);        
        });
      }, []);

    
      useEffect(() => {
    axios.get('https://b2bhubapi.com:7575/compradores', {
      params: {
        foo: currentUser.id
      }}      
      ).then((response) => {
          setseglist(response.data);          
        });
      }, []);





        

const [seleccionado, setseleccionado] = useState({
  id: '',
  RFQsl:'',
  Empresa_solicitante:'',
  Categoria:'',
  Descripcion:'',
  Cantidad_Solicitada:'',
  Unidadmedida:'',
  Precioobj:'',
  diasdecredito:'', 
  Moneda:'', 
  Fechavigencia:'',
  lugaentrega:'',
  Estatussl:'',
  Empresa_interesada:'',
  idui:''
  
});

const [seleccionado2, setseleccionado2] = useState({
  RFQsl:'',
  Empresa_solicitante:'',
  Categoria:'',
  Descripcion:'',
  Cantidad_Solicitada:'',
  Unidadmedida:'',
  Precioobj:'',
  diasdecredito:'', 
  Moneda:'', 
  Fechavigencia:'',
  lugaentrega:'',
  Estatussl:'',
  Empresa_interesada:'',
  idui:'',
  iduc:''
  
});
 const toggle2 = (data) => {
  setseleccionado(data)
  setModale2(!modale2) 
  
 }

  

const toggle = () => {
  
          setModale(!modale)
     
 }

 const toggle3 = (data) => {
   setseleccionado(data)
   setcargador(true)
   setTimeout(()=>{
    setModale(!modale)
    setcargador(false)           
}, 2500)
 }


axios.get('https://b2bhubapi.com:7575/aceptados', {
  params: {
    foo: seleccionado.RFQsl
  }}      
      ).then((response) => {
          setlist(response.data);
        });

      
 const toggle4 = (data) =>{
   setseleccionado2(data)
   setmasig(true)

 }

 
        const aceptarycontinuar = () => { 
          toast.error("Felicidades, tu RFQ ha sido asignado a un Proveedor, se ha enviado un Email para proceder a la entrega del producto", {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 10000
          });
            axios.post('https://b2bhubapi.com:3001/ganadores', 
      
      {RFQsl:seleccionado2.RFQsl,
      Empresa_solicitante:seleccionado2.Empresa_solicitante,
      Categoria:seleccionado2.Categoria,
      Descripcion:seleccionado2.Descripcion,
      Cantidad_Solicitada:seleccionado2.Cantidad_Solicitada,
      Unidadmedida:seleccionado2.Unidadmedida,
      Precioobj:seleccionado2.Precioobj,
      diasdecredito:seleccionado2.diasdecredito, 
      Moneda:seleccionado2.Moneda, 
      Fechavigencia:seleccionado2.Fechavigencia, 
      lugaentrega:seleccionado2.lugaentrega, 
      Estatussl:seleccionado2.Estatussl,
      idug:seleccionado2.idui,
      iduc:seleccionado2.iduc
      }).then((response) => {
        Axios.get('https://b2bhubapi.com:3001/usuariosanotificar3', { 
          params: {
           foo: seleccionado.ui
         }}    
                 ).then((response) => {
                  axios.delete('https://b2bhubapi.com:3001/dltsegundalista', {
                    params: {
                      foo: seleccionado.RFQsl
                    }}      
                    )
                    if(response.data == "" && response.data == null){
                      var usuariosanotificar = []
                    }else{
                      var usuariosanotificar = response.data
                    }
               usuariosanotificar.forEach(usuario=>{
                 var params = {
                   fromName:currentUser.Nombre, 
                   toName:usuario.Nombre, 
                   toEmail:usuario.Correo,
                   
                 }
                   emailjs.send('service_ruxs3u7', 'template_np892ov', params, 'user_6Yiy6j6dBtbUgO2vEhYPP').then((res)=>{
                      console.log('emailjs', res)
                   })
               })
           });
        
    });
    setModale(!modale)
    setmasig(false)
    setTimeout(()=>{
      window.location.reload();                  
      }, 3500)
    };
        
        const eliminar = () => { 
            axios.delete('https://b2bhubapi.com:3001/dltsegundalista', {
              params: {
                foo: seleccionado.id
              }}      
              ).then((response) => {
                SweetAlert.fire({
                  icon:"success",
                  title:"Completado", 
                  text:"Se ha descartado tu cotización"
                }).then(async(result) => {
                        window.location.reload();
                })

                });
                setModale2(!modale2)

                  // window.location.href = `${process.env.PUBLIC_URL}/#/widgets/dashboardcomprador`

                 setTimeout(()=>{
                  window.location.reload();                  
                  }, 800)
              }

              const [expcoti, setexpcoti] = useState(false);
              const [modal, setmodal] = useState(false);
              const [modal2, setmodal2] = useState(false);
              const [modal3, setmodal3] = useState(false);
              const [modal4, setmodal4] = useState(false);
              const [modal5, setmodal5] = useState(false);
              const [cargador2, setcargador2] = useState(false)


  const [nomp, setnomp] = useState("") 
  const [catreg, setcatreg] = useState([])
  const [cantidad, setcantidad] = useState("")
  const [unimed, setunimed] = useState("")
  const [moncot, setmoncot] = useState("")
  const [fechv, setfechv] = useState("")
  const [ubicacion, setubicacion ] = useState([])
  const [desc, setdesc] = useState("")

  const [empr, setempr] = useState("")
  const [nume, setnume] = useState("")
  const [corr, setcorr] = useState("")
  const [contra1, setcontra1] = useState(".")
  const [contra2, setcontra2] = useState("")

              const togglexp = () => {
                setexpcoti(!expcoti)
              }

              const toggle1 = () => {
                setmodal(false)
                setnomp("")
              }
              
              const toggle22 = () => {
                setmodal2(false)
                setnomp("")
              }
              
              
                const toggle33 = () => {
                  setmodal3(false)
                  setnomp("")
                }
                const toggle44 = () => {
                  setmodal4(false)
                  setnomp("")
                }
                const toggle55 = () => {
                  setmodal5(false)
                  setnomp("")
                }
                const togglec = () => {
                  setcargador2(false)
                }


              const crearRFQbutton = () => { 

                  setcargador(false)
                  setexpcoti(true)                
              }
              const clasic = () =>{
                window.location.href = `${process.env.PUBLIC_URL}/#/widgets/crearRFQ`
              }
              const guiada = () =>{
                    setcargador2(true)
                    setexpcoti(false)
                    setTimeout(()=>{
                      setmodal(!modal)
                      setcargador2(false)           
                  }, 1500)

              }
              const paso1 = () =>{
                if(nomp == ""){
                  SweetAlert.fire({title:"Error", text:"Escribe en el campo para continuar", icon:"error"});        
                }
                else{
                  setcargador2(true)
                  setTimeout(() => {     
                    setcargador2(false);
                    setmodal(false)
                    setmodal2(false)
                    setmodal3(true)
                }, 1000);
          
                }
          
              }
              const paso2 = () =>{ 
 
                const cat = JSON.parse(localStorage.getItem('selectedc'))
              
                if(cat == null) {
                  SweetAlert.fire({title:"No haz seleccionado ninguna categoría", text:"Escribe en el campo para buscar una categoría ", icon:"error"});
                }else{
                
                cat.map(data =>{
                  const opcion = data.code + " - " + data.esp
                  setcatreg(opciones=>[...opciones, opcion])  
                  
                })
              
                catreg.forEach(function(elemento, indice, array) {   
                  
                })
                setcargador2(true)
              
                  setTimeout(() => {     
                    setcargador2(false);
                    setmodal3(false)
                    setmodal4(true)
                    // setmodal5(true)
                }, 1000);
                
                }
              }
              const omitir = () => {
                SweetAlert.fire({
                  icon:"info",
                  title:"¿Estás seguro de omitir esta sección?, esto implica romper el alcance de tu cotización.", 
                  cancelButtonColor: "#0B2037",
                  cancelButtonText:"No estoy seguro, quiero volver",
                  confirmButtonText: "Continuar sin categoría",
                  reverseButtons: true,
                  showCancelButton: true,
                }).then((result) => {
                  if(result.isConfirmed == true){
                    setcargador2(true)
                    setTimeout(() => {     
                      setcargador2(false);
                      setmodal3(false)
                      setmodal4(true)
                      // setmodal5(true)
                  }, 1000);
                  }
                });
              }
              const paso3 = () =>{ 


                if (cantidad == "", unimed == "", moncot == "", fechv == "", ubicacion =="", desc == "" ){
                  SweetAlert.fire({title:"Completa los datos", text:"Llena todos los campos para continuar", icon:"error"});
                }
                
                else {
                  setcargador2(true)
                  setTimeout(() => {     
                    setcargador2(false);
                    setmodal4(false)
                    setmodal5(true)
                }, 1000);
                
                }
                }


                const [calle, setcalle] = useState('')
                const [numext, setnumext] = useState('')
                const [colonia, setcolonia] = useState('')
                const tiempoTranscurrido = Date.now();
      const hoy = new Date(tiempoTranscurrido)
      var el = hoy.getDate().toString();
      if (el.length == 1){ //example if 1 change to 01
      el = "0"+ el;
      }
      var getMonth = (hoy.getMonth()+1).toString();
      if (getMonth.length == 1){
      getMonth = "0"+getMonth;
      }
      var getYear = hoy.getFullYear().toString();
      var fecha =  getYear + "-" + getMonth + "-" + el;
      var hora = hoy.getHours() + ':' + hoy.getMinutes();
              
                const paso4 = () => {

                  const union = calle + ' ' + numext + ', ' + colonia + ', ' + muni + ', ' + estado + ', ' +  'CP: ' + cp;

                if (calle == "", numext == "", cp == ""){
                  SweetAlert.fire({title:"Completa los datos", text:"Llena todos los campos para continuar", icon:"error"});
                }else{

                  setcargador2(true)
                  console.log(union)

                  Axios.post('https://b2bhubapi.com:3001/crearrfq', 
                     
                  {RFQsl:nomp,
                  Empresa_solicitante:currentUser.Empresa,
                  Categoria:catreg.join(),
                  Descripcion:desc,
                  Cantidad_Solicitada:cantidad,
                  Unidadmedida:unimed,
                  Precioobj:"",
                  diasdecredito:"", 
                  Moneda:moncot, 
                  Fechavigencia:fechv, 
                  lugaentrega:union, 
                  Estatussl:"Normal",
                  iduc:currentUser.id,
                  Fechadecreacion: fecha,
                  // creadopor:currentuser.id
                  }).then((response) => {
                    Axios.get('https://b2bhubapi.com:3001/usuariosanotificar', { 
                      params: {
                       foo: catreg.join()
                     }}    
                             ).then((response) => {
                              if(response.data == "" && response.data == null){
                                var usuariosanotificar = []
                              }else{
                                var usuariosanotificar = response.data
                              }
                              usuariosanotificar.forEach(usuario=>{
                              var params = {
                              fromName:currentUser.Nombre, 
                              toName:usuario.Nombre, 
                              toEmail:usuario.Correo,
                              RFQreg:nomp,
                              empresa:currentUser.Empresa,
                              catreg:catreg.join(),
                              desreg:desc,
                              csreg:cantidad,
                              umreg:unimed,
                              preoreg:"",
                              diasreg:"", 
                              monreg:moncot, 
                              fechareg:fechv, 
                              lugreg:union, 
                              estreg:"Normal",          
        }
          emailjs.send('service_ruxs3u7', 'template_np892ov', params, 'user_6Yiy6j6dBtbUgO2vEhYPP').then((res)=>{
             console.log('emailjs', res)
          })
        console.log('notificar a', usuario)
      })
      console.log('1', response)
      setTimeout(() => {  
        setcargador2(false);
        setmodal5(false)
      SweetAlert.fire({
        icon:"success",
        title:"¡Felicidades, haz creado tu cotización con éxito!.",    
      }).then(async(result) => {
              window.location.href = (`${process.env.PUBLIC_URL}/#/widgets/dashboardcomprador`)
              window.location.reload();
        })
    }, 1000);
    })        
                  })
                }
                }
               


                const [cp, setcp] = useState("")  
                const [apirespuesta, setapirespuesta] = useState([]) 
                const [muni, setmuni] = useState ('')
                const [estado, setestado] = useState('')
               


                const verificp = () =>{

                  if (cp == ""){
                    SweetAlert.fire({title:"Completa los datos", text:"Coloca un codigo postal valido", icon:"error"});

                  }else{
                    axios.get('https://b2bhubapi.com:4545/codigo_postal', {
                      params: {
                        foo: cp
                      }
                    }      
                      ).then((response) => {
                        if(response.data.length == 0){
                          SweetAlert.fire({title:"ERROR", text:"Coloca un codigo postal valido", icon:"error"});

                          setcp("")
                        }else{
                          setapirespuesta(response.data)
                          setmuni(response.data[0].Municipio)
                          setestado(response.data[0].Estado)
                          setcargador2(true)
                    setTimeout(() => {     
                      setcargador2(false);
                      setapirespuesta(response.data)
                      setmuni(response.data[0].Municipio)
                      setestado(response.data[0].Estado)
                  }, 1200);
                        }
                        });
                    
  
                     
                  }
                    


                 
                    }

                  
              

    return (
      <Fragment>
                <Card>
                <CardHeader>
                <img className="pull-left" src={DSH} style={{height: "35px"}} alt=""/>
                <Button className="btn-pill pull-right"  color="success-gradien"  size="lg" onClick={tuto}>{"Tutorial"}</Button>    
                </CardHeader>
              </Card>  
              <Col xl="6 box-col-12">
              <Card className="o-hidden">
                  <CardBody>
                    <div className="hospital-widgets media">
                      <div className="hospital-box light-bg-danger"><img src={"Heart"} alt=""/><AddchartIcon/></div>
                      <div className="media-body">
                        <div className="hospital-content">
                          <h3 className="d-inline-block f-w-600">{"Solicita una cotización"}</h3>
                          <h6 className="m-t-5 mb-0 f-w-600">{"y recibe ofertas de proveedores"}</h6>
                        </div>
                        <Button  onClick={guiada} className="paso1 btn-pill" color="danger-gradien" size="lg"style={{position:"relative",left: "300px", height: "55px", width: "170px"}} >{"Cotizar"}</Button>
                        <div className="flowers">
                          <div className="flower1 flower-secondary"></div>
                          <div className="flower2 flower-secondary"></div>
                          <div className="flower3 flower-secondary"></div>
                        </div>
                      </div>
                    </div>
                </CardBody>

              </Card> 
              </Col>
              <Modal isOpen={cargador2} toggle={togglec} size="lg" style={{width:'530px'}} centered>
                        <ModalBody>
                        <Col md="12">
                      <div className="loader-box">
                        <div  className="loader-19"></div>
                      </div>
                    </Col>
                        </ModalBody>
                    </Modal>
              <Modal isOpen={expcoti} toggle={togglexp} size="lg" style={{width:'530px'}} centered>
                         <ModalHeader>
                         <h4 className="d-inline-block f-w-600">{"Elige una opción:"}</h4>
                         </ModalHeader>
                        <ModalBody>
                        <h8>{"Mejoramos tu experiencia de compra"}</h8>
                        {/* <font color="#FFFFFF">
                            <h2>{'|'}</h2>
                                </font>
                           <img  style={{position: "relative", left: "115px", width: "200px", height: "200px"}} src={ship} alt=""/> */}
                        </ModalBody>
                        <ModalFooter>
                        <Button color="primary"  onClick={clasic}>{'Cotización Clásica'}</Button>
                        <Button color="success" className="sweet-8"  onClick= {guiada}>{'Cotizacíon Guíada'}</Button>                        
                        </ModalFooter>
                    </Modal>

                    <Modal isOpen={modal} toggle={toggle1} size="lg" centered>
                        <ModalBody>
                        <h3 className="d-inline-block f-w-600">{"¿Qué producto te interesa?"}</h3>
                        <font color="#FFFFFF">
                            <h1>{'|'}</h1>
                                                    <Col md="12">
                          <FormGroup>
                            <Input className="form-control"  onChange= {(e) => {setnomp(e.target.value)}} placeholder="Ejemplo: Tornillos"/>                                
                          </FormGroup>
                        </Col>
                            <h2>{'|'}</h2>
                                </font>
                                <img  style={{width:'200px', height: '200px',position: 'relative' ,left: '285px'}} src={box} alt=""/>
                        </ModalBody>
                        <ModalFooter>
                        <Button color="primary" onClick={toggle1}>{"Cancelar"}</Button>
                        <Button color="secondary" onClick={paso1}>{"Siguiente"}</Button>
                        </ModalFooter>
                    </Modal>
                    
                    <Modal isOpen={modal3} toggle={toggle33} size="lg"  centered>
                        <ModalHeader toggle={toggle33}className="d-inline-block f-w-600">{"Elige una categoría para llegar a más proveedores"}
                        </ModalHeader>
                    <ModalBody>
                    <CategoryUnspc/>
                        </ModalBody>
                        <ModalFooter>                  
                        {/* <Button color="secondary" onClick={omitir}>{"Omitir"}</Button> */}
                        <Button color="primary" onClick={toggle33}>{"Cancelar"}</Button>
                        <Button color="secondary" onClick={paso2}>{"Siguiente"}</Button>
                        </ModalFooter>
                    </Modal>
                    <Modal isOpen={modal4} toggle={toggle44} size="lg" centered>
                        <ModalBody>
                        <h3 className="d-inline-block f-w-600">{"Proporciónanos más detalles de tu cotización."}</h3>
                        <font color="#FFFFFF">
                            <h2>{'|'}</h2>
                                </font>
                        <FormGroup>
                      <Label className="col-form-label">{"Cantidad"}</Label>
                      <Input className="form-control" type="number"  onChange= {(e) => {setcantidad(e.target.value)}} />
                    </FormGroup>
                    <FormGroup>
                    <Label className="col-form-label pt-0">{'Unidad de medida'}</Label>
                        <Input onChange= {(e) => {setunimed(e.target.value)}}type="select" name="select" className="form-control btn-pill digits" id="exampleFormControlSelect7"  >
                        <option>{""}</option>
                          <option>{"Pieza (PZA)"}</option>
                          <option>{"Kilogramo (KG)"}</option>
                          <option>{"Litro (LTO)"}</option>
                          <option>{"Tonelada (TON)"}</option>
                          <option>{"Galón (GL)"}</option> 
                          <option>{"Caja"}</option>
                          <option>{"Conjunto"}</option>
                          <option>{"Kit"}</option>
                          {/* <option>{"Unidad"}</option> */}
                          <option>{"Docena de piezas"}</option>     
                          <option>{"Lote"}</option>        
                        </Input>
                    </FormGroup>
                    <FormGroup>
                    <Label className="col-form-label pt-0">{'Moneda de cotización'}</Label>
                        <Input onChange= {(e) => {setmoncot(e.target.value)}} type="select" name="select" className="form-control btn-pill digits" id="exampleFormControlSelect7"  >
                        <option>{""}</option>
                          <option>{"MXN($)"}</option>
                          <option>{"USD($)"}</option>
                          <option>{"EUR(€)"}</option>
                          <option>{"JPY(¥)"}</option>
                          <option>{"RMB(¥)"}</option>
                        </Input>                        
                    </FormGroup>
                    <FormGroup>
                    <Label className="col-form-label pt-0">{"Fecha de vigencia de la cotización"}</Label>
                        <Input onChange= {(e) => {setfechv(e.target.value)}} type={"date"} placeholder={""} />
                    </FormGroup>          
                    <FormGroup>
                    <Label className="col-form-label pt-0">{'Descripción detallada'}</Label>
                        <Input onChange= {(e) => {setdesc(e.target.value)}}type="textarea" className="form-control" />
                    </FormGroup> 
                        </ModalBody>
                        <ModalFooter>
                        <Button color="primary" onClick={toggle44}>{"Cancelar"}</Button>
                        <Button color="secondary" onClick={paso3}>{"Siguiente"}</Button>
                        </ModalFooter>
                    </Modal>
                    <Modal  isOpen={modal5} toggle={toggle55} size="lg"centered>
                        <ModalBody >
                        <h5 className="d-inline-block f-w-600">{"¿A qué dirección te gustaría recibir tu producto?"}</h5>
                        <font color="#FFFFFF">
                            <h6>{'|'}</h6>
                                </font> 
                                <Row form>
                                <Col md="6">    
                                <FormGroup>
                                <Label className="col-form-label pt-0">{"Coloca tu codigo postal:"}</Label>
                                <Input onChange= {(e) => {setcp(e.target.value)}} placeholder={"Ejemplo: 66436"}  />
                                </FormGroup> 
                                
                                <Button className="btn-pill pull-right"  color="success-gradien"  size="lg" onClick={verificp}>{"Verificar codigo postal"}</Button>
                                </Col>
                                <Col md="6">    
                                <FormGroup>
                                <Label className="col-form-label pt-0">{"Selecciona una colonia:"}</Label>
                                <Input onChange= {(e) => {setcolonia(e.target.value)}} type="select" name="select" className="form-control btn-pill digits" id="exampleFormControlSelect7"  >
                                {apirespuesta.map((data, index) => (
                               <option key={index} >
                                {data.Asentamiento}
                                  </option>
                                 ))}

                        </Input> 
                        
                                </FormGroup> 
                                </Col> 
                                </Row>
                                <Row form>
                                <Col md="6">
                                <FormGroup>
                                <Label className="col-form-label">{"Calle:"}</Label>
                                <Input className="form-control"  onChange= {(e) => {setcalle(e.target.value)}} />    
                                </FormGroup>
                                </Col> 
                                <Col md="6">
                                <FormGroup>
                                <Label className="col-form-label">{"Numero exterior:"}</Label>
                                <Input className="form-control"  onChange= {(e) => {setnumext(e.target.value)}} />    
                                </FormGroup>
                                </Col>
                                </Row>
                    <img style={{ top: "14.812px", position: "relative", left: "233.5px", width: "300px", height: "300px"}} src={entrega_box} alt=""/>


              </ModalBody>
                        <ModalFooter>
                        <Button color="primary" onClick={toggle55}>{"Cancelar"}</Button>
                        <Button color="secondary" onClick={paso4}>{"Crear cotización"}</Button>
                        </ModalFooter>
                    </Modal>

                
        <Tour
          steps={steps}
          rounded={5}
          isOpen={opentour}
          disableInteraction={true}
          disableKeyboardNavigation={false}
          onRequestClose={closeTour}
          onAfterOpen={disableBody}
          onBeforeClose={enableBody}
           />
        <Container fluid={true}>
        <Row>
        
          <Col xl="6 box-col-12">     
          <Card>      
              <CardHeader>
                <h5 className="mb-0 f-24">{"Mis cotizaciones"}</h5>                     
              </CardHeader>              
              <CardBody>
                <div className="paso2 user-status table-responsive">
                  <Table borderless>
                    <thead>
                      <tr>
                      <th scope="col">{"RFQ"}</th>
                          <th scope="col">{"Categoría"}</th>
                          <th scope="col">{"Descripción"}</th>
                          <th scope="col">{"Cantidad solicitada"}</th>
                          <th scope="col">{"Unidad de medida"}</th>
                          {/* <th scope="col">{"Precio Objetivo"}</th> */}
                          {/* <th scope="col">{"Días de acreditación"}</th> */}
                          <th scope="col">{"Moneda"}</th>
                          <th scope="col">{"Fecha de vigencia"}</th>
                          <th scope="col">{"Lugar de entrega"}</th>
                          <th scope="col">{"Estado"}</th>
                          <th scope="col">{"Acciones"}</th>
                      </tr>
                    </thead>
                    <tbody>
                    {seglist.map(data => (
                      <tr >
                         <td>{data.RFQsl}</td>
                         <td>{data.Categoria}</td>
                         <td>{data.Descripcion}</td>
                         <td>{data.Cantidad_Solicitada}</td>
                         <td>{data.Unidadmedida}</td>
                         {/* <td>{data.Precioobj}</td> */}
                         {/* <td>{data.diasdecredito}</td> */}
                         <td>{data.Moneda}</td>
                         <td>{data.Fechavigencia}</td>
                         <td>{data.lugaentrega}</td>
                         <td>
                           <div className={`span badge badge-pill badge-primary`}>{data.Estatussl}</div>
                         </td>
                         <td><Button  className="paso4"  onClick={() => toggle3(data)} >{"Propuestas"}</Button> 
                         <td><Button className="paso5" color="primary" onClick={() => toggle2(data)} >{"Eliminar"}</Button></td> </td>
                         <Modal isOpen={cargador} toggle3={toggle3} size="lg" style={{width:'530px'}} centered>
                        <ModalBody>
                        <Col md="12">
                      <div className="loader-box">
                        <div  className="loader-35"></div>
                      </div>
                    </Col>
                        </ModalBody>
                    </Modal>
                         <Modal isOpen={modale} toggle={toggle} className="modal-body" size="lg" centered={true}>
                         <ModalHeader toggle={toggle}>{'Propuestas de Proveedores'}
                        </ModalHeader>
                        <ModalBody>
                        <CardBody>
                        <div className="user-status table-responsive">
                        <Table borderless>
                      <thead>
                        <tr>
                          <th scope="col">{"RFQ"}</th>
                          <th scope="col">{"Empresa interesada"}</th>
                          <th scope="col">{"Precio ofertado"}</th>
                          <th scope="col">{"Acciones"}</th>
                        </tr>
                      </thead>
                      <tbody>
                      {list.map(data => (
                        <tr >
                        <td>{data.RFQsl}</td>
                        <td>{data.Empresa_solicitante}</td>
                        <td>{data.Precioobj}</td>
                        <td><Button onClick={() => toggle4(data)}  >{"Aceptar"}</Button></td>
                        </tr>
                        ))} 
                         </tbody>
                         </Table>
                         </div>
                         </CardBody>
                        </ModalBody>
                        <ModalFooter>
                        </ModalFooter>
                    </Modal>
                    <Modal isOpen={masig} toggle4={toggle4} className="modal-body"  centered={true}>
                         <ModalHeader >{''}
                        </ModalHeader>
                        <ModalBody>
                        <FormGroup>
                        <label>
                 <h5> Si Aceptas este RFQ, asignaras un ganador a la oferta y estara concluida tu compra, ¿deseas continuar?</h5>                 
              </label>
              </FormGroup>
                        <FormGroup>
                        <label> Precio final : </label>
                        <input className="form-control"  readOnly type="text" value={seleccionado2 && seleccionado2.Precioobj} />
                        </FormGroup>
                        <FormGroup>
                        <label> RFQ: </label>
                        <input className="form-control"  readOnly type="text" value={seleccionado2 && seleccionado2.RFQsl} />
                        </FormGroup>
                        <FormGroup>
                        <label> Categoría: </label>
                        <input className="form-control"  readOnly type="text" value={seleccionado2 && seleccionado2.Categoria}/>
                        </FormGroup>
                        <FormGroup>
                        <label> Empresa solicitante:</label>
                        <input className="form-control"  readOnly type="text" value={seleccionado2 && seleccionado2.Empresa_solicitante}/>
                        </FormGroup>
                        <FormGroup>
                        <label> Producto solicitado: </label>
                        <input className="form-control"  readOnly type="text" value={seleccionado2 && seleccionado2.Descripcion}/>
                        </FormGroup>
                        <FormGroup>
                        <label> Cantidad solicitada: </label>
                        <input className="form-control"  readOnly type="text" value={seleccionado2 && seleccionado2.Cantidad_Solicitada}/>
                        </FormGroup>
                        <FormGroup>
                        <label> Fecha de vigencia: </label>
                        <input className="form-control"  readOnly type="text" value={seleccionado2 && seleccionado2.Fechavigencia}/>
                        </FormGroup>
                        <FormGroup>
                        <label> Estatus </label>
                        <input className="form-control"   readOnly type="text" value={seleccionado2 && seleccionado2.Estatussl}/>
                        {/* añadir campo de attachment */}
                        </FormGroup>
                        
                        </ModalBody>
                        <ModalFooter>
                        <Button color="primary" onClick={toggle4}>{'Cerrar'}</Button>
                        <Button color="secondary"onClick={aceptarycontinuar} >{'Aceptar y continuar'}</Button>
                        
                        </ModalFooter>
                    </Modal>
                    
                    <Modal isOpen={modale2} toggle2={toggle2} className="modal-body"  centered={true}>
                         <ModalHeader toggle2={toggle2}>{'¿Estás seguro de eliminar este RFQ?'}
                        </ModalHeader>
                        <ModalBody>
                        <FormGroup>
                        <label>
                 <h5> Si eliminas este RFQ, será borrado y los proveedores no podrán verlo jamás</h5>                 
              </label>
              <h6>{seleccionado.RFQsl}</h6>
              <h6>{seleccionado.Descripcion}</h6>
                        </FormGroup>
                        </ModalBody>
                        <ModalFooter>
                        <Button color="primary" onClick={toggle2}>{'Cancelar'}</Button>
                        <Button color="success" className="sweet-8" name="alertSuccess" onClick= {eliminar}>{'Confirmar y eliminar'}</Button>                        
                        </ModalFooter>
                    </Modal>
                      </tr>
                    ))} 
                    </tbody>
                  </Table>
                </div>
              </CardBody>            
            </Card>
              </Col>


              <Col xl="6 box-col-12">                  
            
              <Card> 
              <CardHeader>
                <h5 className="mb-0 f-24">{"Cotizaciones asignadas para compra"}</h5>
              </CardHeader>
              <CardBody>
                <div className="paso3 user-status table-responsive">
                  <Table borderless>
                    <thead>
                      <tr>
                      <th scope="col">{"RFQ"}</th>
                   
                          <th scope="col">{"Categoría"}</th>
                          <th scope="col">{"Descripción"}</th>
                          <th scope="col">{"Cantidad solicitada"}</th>
                          <th scope="col">{"Unidad de medida"}</th>
                          {/* <th scope="col">{"Días de acreditación"}</th> */}
                          <th scope="col">{"Moneda"}</th>
                          <th scope="col">{"Fecha de vigencia"}</th>
                          <th scope="col">{"Lugar de entrega"}</th>
                          <th scope="col">{"Precio final"}</th>
                          <th scope="col">{"Estado"}</th>
                      </tr>
                    </thead>
                    <tbody>
                    {terlist.map(data => (
                      <tr >
                         <td>{data.RFQsl}</td>
                  
                         <td>{data.Categoria}</td>
                         <td>{data.Descripcion}</td>
                         <td>{data.Cantidad_Solicitada}</td>
                         <td>{data.Unidadmedida}</td>
                         {/* <td>{data.diasdecredito}</td> */}
                         <td>{data.Moneda}</td>
                         <td>{data.Fechavigencia}</td>
                         <td>{data.lugaentrega}</td> 
                         <td>{data.Precioobj}</td>
                         <td>
                           <div className={`span badge badge-pill badge-primary`}>{data.Estatussl}</div>
                         </td>
                         </tr>
                    ))} 
                    </tbody>
                  </Table>
                </div>
              </CardBody>
              </Card>
          </Col>

          
{/* otra tabla */}
        </Row>
        </Container>
        </Fragment>
    )
}

export default DashboardComprador
