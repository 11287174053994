export class ConfigDB {
    static data = {
         settings: {
             layout_type:  '',
         sidebar: {
             type: 'default',
             body_type: '' 
         },
         sidebar_setting: 'default', 
         sidebar_background_setting: 'sidebar-img1'
         },
         color: {
             layout_version: 'light', 
             color: '', 
             primary_color: '#158df7', 
             secondary_color: '#fb2e63', 
             mix_background_layout: 'light-only', 
         },
         router_animation: 'fadeIn'
     }
   }
export default ConfigDB;


