import React, {useState, useEffect,Fragment } from 'react'
import BreadCrumb from '../../layout/Breadcrumb'
import { DollarSign, TrendingUp, Calendar, Volume2, Phone, Pause, Coffee, Clock, Droplet, Users } from 'react-feather'
import {Container, Row, Col, Card, CardBody, CardHeader, ButtonGroup, Button, Table, CardFooter,Modal, ModalHeader, ModalBody } from 'reactstrap'
import { toast } from 'react-toastify';
import PANEL from "../../assets/images/blog/PANEL.png";
import axios from 'axios';

var Knob = require('knob')

const Panel= () => {


  //Num de prov
  const [numprov, setnumprov] = useState([""])
  const [numcomp, setnumcomp] = useState([""])
  const [nuevosusuarios, setnuevosusuarios] = useState([])
  const [cotnuevos, setcotnuevos] = useState([])

    
  useEffect(() => {
    axios.get('https://b2bhubapi.com:8000/listprov').then((response) => {
          setnumprov(response.data.length);        
        });
      }, []);

      useEffect(() => {
        axios.get('https://b2bhubapi.com:8000/listcomp', ).then((response) => {
              setnumcomp(response.data.length);        
            });
          }, []);
          useEffect(() => {
            axios.get('https://b2bhubapi.com:8000/Usuariosnuevos').then((response) => {
                  setnuevosusuarios(response.data);        
                });
              }, []);
              useEffect(() => {
                axios.get('https://b2bhubapi.com:8000/Cotnuevos').then((response) => {
                      setcotnuevos(response.data);        
                    });
                  }, []);




        


    // eslint-disable-next-line 
    const topCardState = [
      { id: 3, cardBg: 'bg-primary', title: "Compradores", scorr: numcomp, color: 'light', bdgeFont: 'font-primary', bdgeValue: '1.36%', progress: '5%' },
    ]
    const Topcard =[
      { id: 4, cardBg: '', title: "Proveedores", scorr: numprov, color: 'primary', bdgeFont: '', bdgeValue: '6.23%', progress: '35%' }
    ]
  

    const [modal, setModal] = useState(false);
    
    const toggle = () => {
      setModal(!modal)
      localStorage.setItem("isUser",null)
    }

    useEffect(() => {
      
      const LoadingMsg = () => (
        <div>
         <i className="fa fa-bell-o" ></i><strong className="ml-2">{"Cargando..."}</strong>    {""}
        </div>
      )
      if(localStorage.getItem("isUser") === "true"){
        setModal(true)
        toast(<LoadingMsg />, {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 3000,
          });
      }else{
        setModal(false)
      }
      
      var profit = Knob({
      width: 230,
      height: 230,
      thickness: .060,
      angleOffset: 90,
      fgColor: '#fb2e63',
      bgColor: '#f2f4ff',
      lineCap: 'round'
    })
   
     
  }, []);

  const vermasusuarios = () => {
    window.location.href = (`${process.env.PUBLIC_URL}/#/administrador/Usuarios`)

  }
  
  const vermascotizaciones = () => {
    window.location.href = (`${process.env.PUBLIC_URL}/#/administrador/Cotizaciones`)

  }

  
  return (
    <Fragment>
      <Card>
      <CardHeader>
      <img className="pull-left" src={PANEL} style={{height: "35px"}} alt=""/>    
      </CardHeader>
      </Card> 
        <Row>         
          <Col lg="12" className="xl-100">
            <Row>
              {topCardState.map(data => (
                <Col xl="3" md="6" className="xl-50 box-col-6" key={data.id}>
                  <Card className={data.cardBg}>
                    <CardBody className="tag-card">
                      <div className="progressbar-widgets">
                        <div className="media media-widgets">
                          <div className="media-body">
                            <p className={`mb-0 ${data.color === 'light' ? 'font-light' : ''}`}>{data.title}</p>
                            <h3 className="mt-0 mb-0 f-w-600"><span className="counter">{data.scorr}</span><span><TrendingUp /></span></h3>
                          </div>
                        </div>
                        <div className="progress sm-progress-bar progress-animate">
                          <div className={`progress-gradient-${data.color}`} role="progressbar" style={{ 'width': data.progress }} aria-valuenow="75"
                            aria-valuemin="0" aria-valuemax="100">
                            <span className={`font-${data.color}`}>{data.progress}</span><span className="animate-circle"></span></div>
                        </div><span className={`tag-content-${data.color} tag-hover-effect ${data.color === 'light' ? 'tag-light' : ''}`}><TrendingUp /></span>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              ))}

{Topcard.map(data => (
                <Col xl="3" md="6" className="xl-50 box-col-6" key={data.id}>
                  <Card className={data.cardBg}>
                    <CardBody className="tag-card">
                      <div className="progressbar-widgets">
                        <div className="media media-widgets">
                          <div className="media-body">
                            <p className={`mb-0 ${data.color === 'light' ? 'font-light' : ''}`}>{data.title}</p>
                            <h3 className="mt-0 mb-0 f-w-600"><span className="counter">{data.scorr}</span><span><TrendingUp /></span></h3>
                          </div>
                        </div>
                        <div className="progress sm-progress-bar progress-animate">
                          <div className={`progress-gradient-${data.color}`} role="progressbar" style={{ 'width': data.progress }} aria-valuenow="75"
                            aria-valuemin="0" aria-valuemax="100">
                            <span className={`font-${data.color}`}>{data.progress}</span><span className="animate-circle"></span></div>
                        </div><span className={`tag-content-${data.color} tag-hover-effect ${data.color === 'light' ? 'tag-light' : ''}`}><TrendingUp /></span>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              ))}





              
              <Col xl="8" className="xl-50 box-col-12">
                <Card>
                  <CardHeader>
                    <h5>{"Usuarios nuevos"}</h5>
                    <Button  className="btn-pill pull-right" color="warning-gradien"  size="sm" onClick={vermasusuarios}>{"Ver más"}</Button>          
                  </CardHeader>
                  <CardBody className="p-0">
                    <div className="sales-product-table table-responsive">
                    <Table borderless>
                        <thead>
                          <tr>
                            <th scope="col">{"Empresa"}</th>
                            <th scope="col">{"Correo"}</th>
                            <th scope="col">{"Fecha de creación"}</th>
                            <th scope="col">{"Hora"}</th>
                          </tr>
                        </thead>
                        <tbody>
                        {nuevosusuarios.map(data => (
                          <tr>
                            <td><span>{data.Empresa}</span></td>
                            <td><span>{data.Correo}</span></td>
                            <td><span className="badge badge-pill pill-badge-secondary">{data.Fecha}</span></td>
                            <td><span className="badge badge-pill pill-badge-secondary">{data.Hora}</span></td>
                          </tr>
                          ))} 
                        </tbody>
                      </Table>
                    </div>
                  </CardBody>
                </Card>
              </Col>

              <Col xl="8" className="xl-50 box-col-12">
                <Card>
                  <CardHeader>
                    <h5>{"Cotizaciones"}</h5>
                    <Button  className="btn-pill pull-right" color="warning-gradien"  size="sm" onClick={vermascotizaciones}>{"Ver más"}</Button>          
                  </CardHeader>
                  <CardBody className="p-0">
                    <div className="sales-product-table table-responsive">
                    <Table borderless>
                        <thead>
                          <tr>
                            <th scope="col">{"RFQ"}</th>
                            <th scope="col">{"Empresa solicitante"}</th>
                            <th scope="col">{"Cantidad solicitada"}</th>
                            <th scope="col">{"Estado"}</th>
                          </tr>
                        </thead>
                        <tbody>
                        {cotnuevos.map(data => (
                          <tr>
                            <td>{data.RFQsl}</td>
                            <td>
                            <h6 className="f-w-600">{data.Empresa_solicitante}</h6>
                            </td>
                            <td><span>{data.Cantidad_Solicitada}</span></td>
                            <td><span className="badge badge-pill pill-badge-warning">{data.Estatussl}</span></td>
                          </tr>   
                          ))}                       
                        </tbody>
                      </Table>
                    </div>
                  </CardBody>
                </Card>
              </Col>


            </Row>
          </Col>
        </Row>
          <Modal  isOpen={modal} className="welcome-popup modal-dialog-centered ">
            <button  onClick={toggle} className="close" type="button" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
            <ModalBody>
              <ModalHeader></ModalHeader>
              <div className="contain p-50">
                <div className="text-center">
                  <h3>{"Bienvenido al panel de administración"}</h3>
                  <p>{"Comienza analizando los datos de B2BuySel"} </p>
                  <button 
                    onClick={toggle}
                    className="btn btn-primary btn-lg txt-white" 
                    type="button" data-dismiss="modal" 
                    aria-label="Close">{"Empezar"}</button>
                </div>
              </div>
            </ModalBody>
          </Modal>
     
      </Fragment>
    
  )
}

export default Panel
