import React, { Fragment } from 'react';
import BreadCrumb from '../../layout/Breadcrumb'
import {Container,Row,Col,Card,CardHeader,CardBody,Form,FormGroup,Input,Label,Button} from 'reactstrap'
import { getCartTotal } from "../../services/ecommerce.service"
import {useForm} from 'react-hook-form'
import {useSelector} from 'react-redux'
import {Link,useHistory} from 'react-router-dom'
import { BillingDetails, FirstName, LastName, Phone, EmailAddress, Country, CountryMenu, Address,TownCity,StateCountry,PostalCode,Product,Total,Subtotal,Shipping,Option1,Option2,PlaceOrder,CheckMeOut,CheckPayments,CashOnDelivery,PayPal,ContinueShopping } from '../../constant';

const Check = (props) => {
  const currentUser= useSelector(state => state.Common.currentUser)
    const history = useHistory();
    const {register,handleSubmit,errors} = useForm()
    const onSubmit = data => {
      if (data !== '') {
         alert('You submitted the form and stuff!');
         history.push(`${process.env.PUBLIC_URL}/ecommerce/invoice`)
      } 
      else {
          errors.showMessages();
        }
     }

     const cart = useSelector(content => content.Cartdata.cart);
     const symbol = useSelector(content => content.data.symbol);

    return (
        <Fragment>
        <Container fluid={true}>
        <Card>
         </Card>
            <Card className="checkout">
              <CardHeader>
                <h5>{"Detalles de la compra"}</h5>
              </CardHeader>
              <CardBody>
                <Row>
                  <Col lg="6" sm="12">
                    <Form onSubmit={handleSubmit(onSubmit)} className="needs-validation"> 
                      <div className="form-row">
                      <FormGroup className="col-sm-6">
                          <Label>{"Nombre"}</Label>
                          <Input  type="text"  placeholder={currentUser.Nombre} />
                        </FormGroup>
                        <FormGroup  className="col-sm-6">
                          <Label>{"Apellidos"}</Label>
                          <Input  type="text" placeholder={currentUser.Apellido} />
                        </FormGroup>
                      </div>
                      <div className="form-row">
                        <FormGroup className="col-sm-6">
                          <Label>{"Numero telefonico"}</Label>
                          <Input  type="text" placeholder={currentUser.Telefono}/>
                        </FormGroup>
                        <FormGroup className="col-sm-6">
                          <Label>{"Dirección de correo electronico"}</Label>
                          <Input type="text"  pattern={/^\S+@\S+$/i} placeholder={currentUser.Correo} />
                        </FormGroup>
                      </div>
                      <FormGroup>
                        <Label>{"Pais"}</Label>
                        <Input type="select" name="selectMulti">
                        <option>{"México (MX)"}</option>
                          <option>{"España (ESP)"}</option>
                          <option>{"Colombia (COL)"}</option>   
                        </Input>
                      </FormGroup>
                      <FormGroup>
                        <Label for="inputAddress5">{"Dirección"}</Label>
                        <Input type="text"  />
                      </FormGroup>
                      <FormGroup>
                        <Label for="inputCity">{"Ciudad"}</Label>
                        <Input type="text" />
                      </FormGroup>
                      <FormGroup>
                        <Label for="inputAddress2">{"Estado"}</Label>
                        <Input type="text"  />
                      </FormGroup>
                      <FormGroup>
                        <Label for="inputAddress6">{"Codigo postal"}</Label>
                        <Input type="text"   />
                      </FormGroup>
                      {/* <FormGroup>
                          <Input  type="checkbox"className="checkbox_animated"/>
                          <Label>{CheckMeOut}</Label>
                      </FormGroup> */}

                        {/* <Button color="primary" type="submit" className="mt-2 pull-right">{PlaceOrder}</Button> */}
                    
                    </Form>
                  </Col>
                  <Col lg="6" sm="12">
                    <div className="checkout-details">
                      <div className="order-box">
                        <div className="title-box">
                          <div className="checkbox-title">
                            <h4>{"Producto"} </h4><span>{Total}</span>
                          </div>
                        </div>
                        <ul className="qty">
                            <li key={"index"}>{"Licencia Negocios B2Buysel"} × {"Suscripción de 1 mes"} <span>{symbol} {"50.00 USD"}</span></li>
                        </ul>
                        <ul className="sub-total">
                          <li>{Subtotal} <span className="count">{"$50.00 USD"}</span></li>
                          <li className="shipping-class">{"Renovación de la compra"}
                            <div className="shopping-checkout-option">
                              <Label className="d-block">
                                <Input className="checkbox_animated"  type="checkbox"/>{"Renovar la licencia automaticamente cuando caduque"}
                              </Label>
                              {/* <Label className="d-block">
                                <Input className="checkbox_animated"  type="checkbox" defaultChecked/>{"Deseo renovar manualmente"}
                              </Label> */}
                            </div>
                          </li>
                        </ul>
                        {/* <ul className="sub-total total">
                          <li>{Total} <span className="count">{symbol} {getCartTotal(cart)}</span></li>
                        </ul> */}
                        {/* <div className="animate-chk">
                          <Row>
                            <Col>
                              <Label className="d-block">
                                <Input className="radio_animated"  type="radio" name="rdo-ani"/>{""}
                              </Label>
                              <Label className="d-block">
                                <Input className="radio_animated"  type="radio" name="rdo-ani" />{CashOnDelivery}
                              </Label>
                              <Label className="d-block">
                                <Input className="radio_animated"  type="radio" name="rdo-ani" defaultChecked />{PayPal}
                              </Label>
                            </Col>
                          </Row>
                        </div> */}
                        <div className="text-right mt-2">
                        <Link
                            to={`${process.env.PUBLIC_URL}/widgets/Pago`}
                          >
                            <Button
                              color="primary"
                              className="cart-btn-transform"
                            >
                              {"Continuar al pago"}
                            </Button>
                          </Link> 
                          </div>
                      </div>
                    </div>
                  </Col>
                </Row >
              </CardBody>
            </Card>
          </Container>
          </Fragment>
    );
}

export default Check;